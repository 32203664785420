import React, { useEffect, useState, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Button, Container, InnerContainer, MainTitle, Paragraph } from "app-premitives/index";
import recordingIcon from 'app-assets/images/recording-icon.png';
import playRecordingIcon from 'app-assets/images/play-circle.png';
import redoRecordingIcon from 'app-assets/images/repeat-icon.png';
import backButton from 'app-assets/images/caret-left-solid.svg';
import stopButton from 'app-assets/images/stop-button.png';
import { selectImage, selectGiftData, selectScreenInfo, selectConfigs, selectMerchant } from 'app-root/pages/merchant/merchant.selector';
import { getScreenInfo, setAudioUrlByRecording } from "../merchant.slice";
import usePageTimeout from "app-root/core/theme/page-timeout.hook";
const MicRecorder = require('mic-recorder-to-mp3');

interface CustomCSSProperties extends React.CSSProperties {
  '--delay'?: string;
}

const RecordVoice: React.FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [isPlaying, setIsPlaying] = useState(false);
  const [isRedo, setIsRedo] = useState(false);
  const [countdown, setCountdown] = useState(3);
  const [isCountdownVisible, setIsCountdownVisible] = useState(false);
  const [recordingLimit] = useState(60); // 1 minute recording limit

  const giftData = useSelector(selectGiftData);
  const imageData = useSelector(selectImage);
  const dispatch = useDispatch();

  const [audioUrl, setAudioUrl] = useState<string | null>(null);
  const [audio, setAudio] = useState<HTMLAudioElement | null>(null);
  const [currentTime, setCurrentTime] = useState(0);
  const [isRecordingStopped, setIsRecordingStopped] = useState(false);
  const [isStartRecording, setIsStartRecording] = useState(false);
  const [recordedTime, setRecordedTime] = useState(0);
  const [audioBase64, setAudioBase64] = useState<string | null>(null);
  const [totalRecordedTime, setTotalRecordedTime] = useState<number | null>(null);
  const screenInfo = useSelector(selectScreenInfo);
  const [screen, setScreen] = useState<any>(null);
  const isRedirect = screenInfo?.screenData?.find((screens: any) => screens.screenId === 1)?.data?.redirectToMerchentSite;

  const config = useSelector(selectConfigs);
  const merchant = useSelector(selectMerchant);

  const recorderRef = useRef<any>(null);
  const [isRecording, setIsRecording] = useState(false);

  usePageTimeout();

  useEffect(()=>{
    if(!giftData.senderfname && !giftData.sendermail && !giftData.recieverfname && !giftData.activeOccasion){
      navigate(`/${id}/`)
    }
  
},[])

  useEffect(() => {
    console.log('screen info', screenInfo.screenData)
    if (merchant && screenInfo.screenData === undefined) {
      dispatch(getScreenInfo({merchantId: merchant?.merchantId}))
    }
  }, [merchant]);

  useEffect(() => {
    let intervalId: NodeJS.Timeout;

    if (isStartRecording) {
      intervalId = setInterval(() => {
        setRecordedTime((prevTime) => prevTime + 1);
      }, 1000);
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [isStartRecording]);

  useEffect(() => {
    if (audioUrl) {
      const audioElement = new Audio(audioUrl);
      setAudio(audioElement);
      audioElement.addEventListener("timeupdate", () => {
        setCurrentTime(audioElement.currentTime);
      });
    }
  }, [audioUrl]);

  useEffect(() => {
    setTotalRecordedTime(recordedTime);
  }, [recordedTime]);

  useEffect(() => {
    if (screenInfo && screenInfo.screenData) {
      setScreen(screenInfo.screenData.find((screens: any) => screens.screenId === 8));
    }
  }, [screenInfo]);

  const formatTime = (timeInSeconds: number): string => {
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = Math.floor(timeInSeconds % 60);
    return `0${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  };

  const handleRedo = () => {
    if (isPlaying) {
      audio?.pause();
      setIsPlaying(false);
    }
    if (recorderRef.current && recorderRef.current.state === "recording") {
      recorderRef.current.stop();
    }
    setRecordedTime(0);
    setIsRecordingStopped(false);
    setAudioUrl(null);
    setAudioBase64(null);
    setCountdown(3);
    setIsCountdownVisible(false);
  };

  const startRecording = () => {
    recorderRef.current = new MicRecorder({ bitRate: 128 });

    recorderRef.current.start().then(() => {
      setIsRecording(true);

      setTimeout(() => {
        if (isRecording) {
          console.log("Stopping recording due to time limit");
          stopRecording();
        }
      }, recordingLimit * 1000);
    }).catch((e: any) => {
      console.error("Microphone access denied: ", e);
    });
  };

  const stopRecording = () => {
    if (isRecording) {
      recorderRef.current.stop().getMp3().then(([buffer, blob]: [ArrayBuffer, Blob]) => {
        const url = URL.createObjectURL(blob);
        setAudioUrl(url);

        const reader = new FileReader();
        reader.onloadend = () => {
          const base64String = reader.result?.toString();
          setAudioBase64(base64String || null);
        };
        reader.readAsDataURL(blob);

        setIsRecordingStopped(true);
        setIsStartRecording(false);
        setIsRecording(false);
      }).catch((e: any) => {
        console.error("Recording error: ", e);
      });
    } else {
      console.log("Recorder is not in recording state");
    }
  };

  const handlePlay = () => {
    if (audioUrl) {
      if (isPlaying) {
        audio?.pause();
        audio!.currentTime = 0;
        setIsPlaying(false);
      } else {
        audio?.play();
        audio?.addEventListener("ended", () => {
          audio.currentTime = 0;
          setIsPlaying(false);
        });
        setIsPlaying(true);
      }
    }
  };

  const startCountdown = () => {
    setIsCountdownVisible(true);
    setCountdown(3);

    const countdownInterval = setInterval(() => {
      setCountdown((prevCountdown) => prevCountdown - 1);
    }, 1000);

    setTimeout(() => {
      clearInterval(countdownInterval);
      setIsCountdownVisible(false);
      setIsStartRecording(true);
      startRecording();
    }, 3000);
  };

  const requestMicrophoneAccess = () => {
    navigator.mediaDevices.getUserMedia({ audio: true })
      .then(() => {
        startCountdown();
      })
      .catch((error) => {
        console.error("Microphone access denied: ", error);
      });
  };

  const navigateContinue = () => {
    if (isPlaying) {
      setIsPlaying(false);
      audio?.pause();
      audio!.currentTime = 0;
    }
    dispatch(setAudioUrlByRecording({ totalRecordedTime, audioBase64 }));
    navigate(`/${id}/preview`);
  };

  return (
    <Container>
      <div className="flex justify-center h-full md:h-auto">
        <InnerContainer bgColor={merchant?.backgroundColor} hrefLink={isRedirect ? `../${id}` : '../'} merchantLogo={merchant?.logo} header={
          <div className="flex items-center text-[12px] font-medium cursor-pointer" onClick={() => {
            navigate(`/${id}/imageupload`);
          }}>
            <img src={backButton} className="h-[12px] w-[12px]" />
            Back
          </div>
        }>
          <div className="h-full">
            <div className="h-[10%] md:h-[20%]">
              <MainTitle size="3xl" align="center">
                {screen ? screen.data.title : ''}
              </MainTitle>
              <div className="mt-4 mb-10">
                <Paragraph align="center" classNames="h-28">
                  <div dangerouslySetInnerHTML={{ __html: screen?.data?.description }} />
                </Paragraph>
              </div>
            </div>

            <div id="record" className="flex h-[80%] md:h-[75%] mt-[80px] md:mt-[0px] flex-col items-center">
              <div className="relative flex flex-col items-center bg-white h-full w-full rounded-[24px]">
                <img
                  className="shadow w-[180px] mt-[-60px] rounded-2xl object-cover"
                  src={giftData?.imageString ? giftData?.imageString : giftData?.activeOccasion?.occasionBg}
                  alt="Uploaded Image"
                />

                <div className="flex flex-col items-center justify-between w-full h-full p-4">
                  <div className="flex flex-col items-center justify-center py-4">
                  <div className="flex items-center justify-center ">
                      <div className="audioWaves">
                        {[0, 0.2, 0.4, 0.6, 0.8, 0.6, 0.4, 0.2, 0].map((delay, index) => (
                          <span key={index} className={`stroke ${isStartRecording || isPlaying ? 'stroke-animation' : ''}`} style={{ '--delay': `${delay}s`, background: `${merchant?.buttonBackgroundColor}` } as CustomCSSProperties}></span>
                        ))}
                      </div>
                    </div>

                    <p className="font-semibold lg:mt-[20px] text-[20px]">
                      {isStartRecording
                        ? formatTime(recordedTime)
                        : isPlaying
                          ? formatTime(currentTime)
                          : isRecordingStopped
                            ? formatTime(totalRecordedTime!)
                            : isCountdownVisible
                              ? `${countdown}s`
                              : 'Ready to record'}
                    </p>
                  </div>

                  <div className="flex flex-col items-center w-full">
                    <div className="flex flex-col w-full gap-4 pb-4">
                      <div className="flex gap-2">
                        {isRecordingStopped && (
                          <Button type="normal" btnBgColorHover={merchant?.buttonHoverBackgroundColor} btnTextColorHover={merchant?.buttonHoverTextColor} btnBorderColorHover={merchant?.buttonHoverBorderColor} fullwidth={true} onClick={handleRedo}>
                            <div className="flex items-center justify-center gap-2">
                              <img src={redoRecordingIcon} className="h-[20px]" />
                              {screen ? screen.data.redoButton : ''}
                            </div>
                          </Button>
                        )}
                        {isRecordingStopped && (
                          <Button
                            btnTextColor={merchant?.buttonTextColor} btnBgColor={merchant?.buttonBackgroundColor} btnBgColorHover={merchant?.buttonHoverBackgroundColor} btnBorderColor={merchant?.buttonBorderColor} btnTextColorHover={merchant?.buttonHoverTextColor} btnBorderColorHover={merchant?.buttonHoverBorderColor}
                            type="base"
                            fullwidth={true}
                            onClick={handlePlay}
                          >
                            <div className="flex items-center justify-center gap-2">
                              <img src={isPlaying ? stopButton : playRecordingIcon} className="h-[20px]" />
                              {isPlaying ? "Stop" : `${screen ? screen.data.playButton : ''}`}
                            </div>
                          </Button>
                        )}
                      </div>

                      {!isRecordingStopped && !isStartRecording && (
                        isCountdownVisible ? (
                          <Button type="base"
                            btnTextColor={merchant?.buttonTextColor} btnBgColor={merchant?.buttonBackgroundColor} btnBgColorHover={merchant?.buttonHoverBackgroundColor} btnBorderColor={merchant?.buttonBorderColor} btnTextColorHover={merchant?.buttonHoverTextColor} btnBorderColorHover={merchant?.buttonHoverBorderColor}
                            fullwidth={true}>
                            {`Recording starts in ${countdown}`}
                          </Button>
                        ) : (
                          <Button type="base"
                            btnTextColor={merchant?.buttonTextColor} btnBgColor={merchant?.buttonBackgroundColor} btnBgColorHover={merchant?.buttonHoverBackgroundColor} btnBorderColor={merchant?.buttonBorderColor} btnTextColorHover={merchant?.buttonHoverTextColor} btnBorderColorHover={merchant?.buttonHoverBorderColor}
                            fullwidth={true} onClick={requestMicrophoneAccess}>
                            <div className="flex justify-center gap-2">
                              <img src={recordingIcon} />
                              {screen ? screen.data.recordButton : ''}
                            </div>
                          </Button>
                        )
                      )}

                      {isRecordingStopped && (
                        <Button type="base" btnTextColor={merchant?.buttonTextColor} btnBgColor={merchant?.buttonBackgroundColor} btnBgColorHover={merchant?.buttonHoverBackgroundColor} btnBorderColor={merchant?.buttonBorderColor} btnTextColorHover={merchant?.buttonHoverTextColor} btnBorderColorHover={merchant?.buttonHoverBorderColor} fullwidth={true} onClick={navigateContinue}>
                          {screen ? screen.data.continueButton : ''}
                        </Button>
                      )}

                      {isStartRecording && (
                        <Button type="stop" fullwidth={true} extraClass="bg-[#FF8579]" onClick={stopRecording}>
                          <div className="flex justify-center gap-2">
                            <img src={recordingIcon} />
                            {screen ? screen.data.recordStopButton : ''}
                          </div>
                        </Button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </InnerContainer>
      </div>
    </Container>
  );
};

export default RecordVoice;