import { ofType, combineEpics } from 'redux-observable';
import { switchMap, tap, mergeMap } from 'rxjs/operators';
import { of } from "rxjs";
import { getPaymentStatus, setPaymentStatus, getMerchants, setPlayMessage, getOccassion, setMerchants, setOccassion, setInvalidMerchant, getTokens, setTokens, setInvalidToken, getConfig, setConfig, getImageUrlByUploadedImage, setImageUrlByUploadedImage, getAudioUrlByRecording, setAudioUrlByRecording, getCreateMessage, setCreateMessage, getPlayMessage, getTokenStatus, setTokenStatus, getDefaultMerchant, setDefaultMerchant, getScreenInfo, setScreenInfo, getMessage, setMessage } from "app-pages/merchant/merchant.slice"
import { apiUrl } from "app-core/utils/apiUrl"
import { httpGet, httpPost } from "app-core/http"

export const epicGetAllOccassions = (action$) => {
    return action$.pipe(
        ofType(getOccassion.type),
        switchMap(({ payload }) =>
            httpGet({
                call: apiUrl().getAllOccassions,
  
            }).pipe(
                mergeMap(({ result, statusCode }) => {
                    if (statusCode == 200) {
                        //  const parsedResults = JSON.stringify(result)
                        // localStorage.setItem("AllOccassions", parsedResults)
                        return of(
                            setOccassion(result),
                        );
                        
                    }
                }))
        )
    );
};

export const epicGetMerchants = (action$) => {
    return action$.pipe(
        ofType(getMerchants.type),
        switchMap(({ payload }) =>
            httpPost({
                call: apiUrl().getMerchantByName(payload.merchantId),
            }).pipe(
                mergeMap(({ result, statusCode }) => {
                    if (statusCode == 200) {
                        return of(
                            setMerchants(result)
                        );
                    }
                    else if (statusCode == -99) {
                        return of(
                            setInvalidMerchant()
                        );
                    }
                }))
        )
    );
};

export const epicGetTokens = (action$) => {
    return action$.pipe(
        ofType(getTokens.type),
        switchMap(({ payload }) =>
            httpGet({
                call: apiUrl().getTokenByGiftcode(payload.giftCode),
            }).pipe(
                mergeMap(({ result, statusCode }) => {
                        const parsedResults = JSON.stringify(result)
                        localStorage.setItem("Tokens", parsedResults)
                    if (statusCode == 200) {
                        return of(
                            setTokens(result)
                        );
                    }
                    else if (statusCode == -99) {
                        return of(
                            setInvalidToken()
                        );
                    }
                }))
        )
    );
};

export const epicGetAllConfigs = (action$) => {
    return action$.pipe(
        ofType(getConfig.type),
        switchMap(({ payload }) =>
            httpGet({
                call: apiUrl().getConfigData,
            }).pipe(
                mergeMap(({ result, statusCode }) => {
                    if (statusCode == 200) {
                        return of(
                            setConfig(result)
                        );
                    }
                }))
        )
    );
};

export const epicGetImageUrlByUploadedImage = (action$) => {
    return action$.pipe(
        ofType(getImageUrlByUploadedImage.type),
        switchMap(({ payload }) =>
            httpPost({
                call: apiUrl().getImageUrlByUploadedImage,
                data : payload
            }).pipe(
                mergeMap(({ result, statusCode }) => {
                    if (statusCode == 200) {
                        return of(
                            setImageUrlByUploadedImage(result)
                        );
                    }
                    else if (statusCode == -99) {
                        console.log("Image Upload Failed!");
                    }
                }))
        )
    );
};

export const epicGetAudioUrlByRecording= (action$) => {
    return action$.pipe(
        ofType(getAudioUrlByRecording.type),
        switchMap(({ payload }) =>
            httpPost({
                call: apiUrl().getAudioUrlByRecording,
                data : payload
            }).pipe(
                mergeMap(({ result, statusCode }) => {
                    if (statusCode == 200) {
                        return of(
                            setAudioUrlByRecording(result)
                        );
                    }
                    else if (statusCode == -99) {
                        console.log("Recording Failed!");
                    }
                }))
        )
    );
};

export const epicGetCreateMessage= (action$) => {
    return action$.pipe(
        ofType(getCreateMessage.type),
        switchMap(({ payload }) =>
            httpPost({
                call: apiUrl().getCreateMessage,
                data : payload
            }).pipe(
                mergeMap(({ result, statusCode }) => {
                    if (statusCode == 200) {
                        return of(
                            setCreateMessage(result)
                        );
                    }
                    else if (statusCode == -99) {
                        setCreateMessage({...result, invalidToken: true})
                    }
                }))
        )
    );
};

export const epicGetPlayMessage = (action$) => {
    return action$.pipe(
      ofType(getPlayMessage.type),
      switchMap(({ payload }) => {
        return httpGet({
          call: apiUrl().getPlayMessage(payload.merchantId, payload.giftCode),
        }).pipe(
          mergeMap(({ result, statusCode }) => {
                    if (statusCode == 200) {
                        return of(
                            setPlayMessage({result, statusCode})
                        );
                    }
                    else if (statusCode == -99) {
                        return of(
                            setPlayMessage()
                        );
                    }
                })
                );
            })
        );
    };

    export const epicGetMessage = (action$) => {
        return action$.pipe(
          ofType(getMessage.type),
          switchMap(({ payload }) => {
            return httpGet({
              call: apiUrl().getMessage(payload.merchantId, payload.giftCode),
            }).pipe(
              mergeMap(({ result, statusCode }) => {
                        if (statusCode == 200) {
                            return of(
                                setMessage({result, statusCode})
                            );
                        }
                        else if (statusCode == -99) {
                            return of(
                                setMessage()
                            );
                        }
                    })
                    );
                })
            );
        };

    export const epicGetTokenStatus = (action$) => {
        return action$.pipe(
          ofType(getTokenStatus.type),
          switchMap(({ payload }) => {
            return httpGet({
              call: apiUrl().getTokenStatus(payload.merchantId, payload.giftCode, payload.action),
            }).pipe(
              mergeMap(({ message,result, statusCode }) => {
                            
                           
                        if (statusCode == 200) {
                            return of(
                                setTokenStatus({
                                    'message' : message,
                                    'result' : result,
                                    'status_code' : statusCode
                                })
                            );
                        }
                        else if (statusCode == -99) {
                            return of(
                                setInvalidToken(),
                                setTokenStatus({
                                    'message' : message,
                                    'result' : result,
                                    'status_code' : statusCode
                                })
                            );
                        }
                    })
                    );
                })
            );
        };

        export const epicGetPaymentStatus = (action$) => {
            return action$.pipe(
                ofType(getPaymentStatus.type),
                switchMap(({ payload }) =>
                    httpGet({
                        call: apiUrl().getPaymentStatus(payload.merchantId),
                    }).pipe(
                        mergeMap(({ result, statusCode }) => {
                            const parsedResults = JSON.stringify(result)
                            localStorage.setItem("PaymentStatus", parsedResults)
                            if (statusCode == 200) {
                                return of(
                                    setPaymentStatus(result)
                                );
                            }
                            else if (statusCode == -99) {
                                return of(
                                    setPaymentStatus()
                                );
                            }
                        }))
                )
            );
        };

        export const epicDefaultMerchant = (action$) => {
            return action$.pipe(
                ofType(getDefaultMerchant.type),
                switchMap(({ payload }) =>
                    httpGet({
                        call: apiUrl().getDefaultMerchant,
                    }).pipe(
                        mergeMap(({ result, statusCode }) => {
                            if (statusCode == 200) {
                                return of(
                                    setDefaultMerchant(result),
                                );
                            }
                        }))
                )
            );
        };

        export const epicScreenInfo = (action$) => {
            return action$.pipe(
                ofType(getScreenInfo.type),
                switchMap(({ payload }) =>
                    httpGet({
                        call: apiUrl().getScreenInfo(payload.merchantId),
                    }).pipe(
                        mergeMap(({ result, statusCode }) => {
                            if (statusCode == 200) {
                                return of(
                                    setScreenInfo(result)
                                );
                            }
                            else if (statusCode == -99) {
                                return of(
                                    setScreenInfo()
                                );
                            }
                        }))
                )
            );
        };



const merchantEpic = combineEpics(
    epicGetAllOccassions,
    epicGetMerchants,
    epicGetTokens,
    epicGetAllConfigs,
    epicGetImageUrlByUploadedImage,
    epicGetAudioUrlByRecording,
    epicGetCreateMessage,
    epicGetPlayMessage,
    epicGetTokenStatus,
    epicGetPaymentStatus,
    epicDefaultMerchant,
    epicScreenInfo,
    epicGetMessage
);

export default merchantEpic;