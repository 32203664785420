import { createSlice } from '@reduxjs/toolkit';
import { setRecordData, setOccassionsData, setMerchantData, setConfigData, setAudioData, setCreateMessageData, setPlayMessageData, setDefaultMerchantData, setScreenInfoData, setDraftData } from './utils/useRecordData';

const initialState = { finalUpdateMode : {text : false, image: false, audio: false}, occassions: [], merchant: {}, invalidMerchant: false, token: [], invalidToken:true, giftdata: {}, configs: [], saveImage: null, saveRecording: {}, createMessage: [], message: [], paymentStatus: [] , theme:[], screenInfo: {}, draftMessage: {}, ready: false, isTokenPresent: false }

const merchantSlice = createSlice({
    name: 'merchant',
    initialState,
    reducers: {
        getThemee(){ },
        setThemee(state, action){
            state.theme = action.payload
        },
        resetState(state) {
            return initialState;
        },
        getOccassion() { },
        setOccassion(state, action) {
            setOccassionsData(action.payload)
            state.occassions = action.payload
        },
        getMerchants(state, action) { },
        setMerchants(state, action) {
            if(state.ready === false){
                //localStorage.clear();
                state.ready = true;
            }
            setMerchantData(action.payload)
            state.merchant = action.payload
            state.invalidMerchant = false;
        },
        getTokens(state, action) { },
        setTokens(state, action) {
            // setTokenData(action.payload)
            state.token = action.payload
            state.invalidToken = false;
        },
        setGiftdata(state, action) {
            setRecordData(action.payload)
            state.giftdata = {...state.giftdata, ...action.payload}
        },
        setInvalidMerchant(state) {
            state.invalidMerchant = true;
        },
        setInvalidToken(state, action) {
            state.invalidToken = true;
        },
        getConfig() { },
        setConfig(state, action) { 
            setConfigData(action.payload)
            state.configs = action.payload
        },
        getImageUrlByUploadedImage(state, action) { },
        setImageUrlByUploadedImage(state, action) { 
            state.saveImage = action.payload
        },
        getAudioUrlByRecording(state, action) { },
        setAudioUrlByRecording(state, action) {
            setAudioData(action.payload)
            state.saveRecording = action.payload
        },
        getCreateMessage(state, action) { },
        setCreateMessage(state, action) {
            setCreateMessageData(action.payload)
            state.createMessage = action.payload
        },
        setFinalUpdateMode(state, action){
            state.finalUpdateMode = action.payload
        },
        getPlayMessage(state, action) { },
        setPlayMessage(state, action) {
            setPlayMessageData(action.payload) 
            state.message = action.payload
         },
        getTokenStatus(state, action) { },
        setTokenStatus(state, action) { 
            // setTokenData(action.payload)
            state.token = action.payload
            state.invalidToken = false;
         },
        getPaymentStatus(state, action) { },
        setPaymentStatus(state, action) { 
            state.paymentStatus = action.payload
         },
        getDefaultMerchant() {},
        setDefaultMerchant(state, action) {
            setDefaultMerchantData(action.payload)
            state.merchant = action.payload
        },
        getScreenInfo(state, action) {},
        setScreenInfo(state, action) {
            setScreenInfoData(action.payload)
            state.screenInfo = action.payload
        },
        getMessage(state, action) {},
        setMessage(state, action) {
            setDraftData(action.payload)
            state.draftMessage = action.payload
        },
        getIsTokenPresent(state, action) {},
        setIsTokenPresent(state, action) {
            state.isTokenPresent = action.payload;
        }
    }
});

export const { getIsTokenPresent, setIsTokenPresent, resetState, getPaymentStatus, setPaymentStatus, getPlayMessage, setPlayMessage, setFinalUpdateMode, getOccassion, setOccassion, getMerchants, setMerchants, setInvalidMerchant, getTokens, setTokens, setInvalidToken, setGiftdata, getConfig, setConfig, getImageUrlByUploadedImage, setImageUrlByUploadedImage, getAudioUrlByRecording, setAudioUrlByRecording, getCreateMessage, setCreateMessage, getTokenStatus, setTokenStatus, getDefaultMerchant, setDefaultMerchant, getThemee, setThemee, getScreenInfo, setScreenInfo, getMessage, setMessage } = merchantSlice.actions
export default merchantSlice.reducer