import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { MainTitle, Container, InnerContainer, Button } from "app-premitives/index";
import { useDispatch, useSelector } from "react-redux";
import { getCreateMessage, getOccassion, getImageUrlByUploadedImage, setFinalUpdateMode, getAudioUrlByRecording, getPaymentStatus, getScreenInfo } from "app-pages/merchant/merchant.slice";
import { selectFinalUpdateMode, selectImage, invalidToken, selectMerchant, selectGiftData, selectRecording, selectConfigs, selectPaymentStatus, selectTheme, selectScreenInfo, selectCreateMessage } from 'app-root/pages/merchant/merchant.selector'
import playCircle from 'app-assets/images/playcircleaudio.png'
import backButton from 'app-assets/images/caret-left-solid.svg'
import closeIcon from 'app-assets/images/close-icon.png'
import useMerchantInfo from "app-core/theme/theme.hook";
import usePageTimeout from "app-root/core/theme/page-timeout.hook";

interface CustomCSSProperties extends React.CSSProperties {
  '--delay'?: string;
}

const Preview: React.FC = () => {
  const { id } = useParams();

  const mode = useSelector(selectFinalUpdateMode);
  const message = useSelector(selectCreateMessage);
  const recording = useSelector(selectRecording);

  const giftData = useSelector(selectGiftData);
  const isInvalidToken = useSelector(invalidToken);
  const merchant = useSelector(selectMerchant);
  const imageData = useSelector(selectImage);
  const recordingData = useSelector(selectRecording);
  const config = useSelector(selectConfigs);
  const paymentStatus = useSelector(selectPaymentStatus)
  const createMessage = useSelector(selectCreateMessage)
  const screenInfo = useSelector(selectScreenInfo);
  const [screen, setScreen] = useState(null) as any

  const [isPlaying, setIsPlaying] = useState(false);
  const [audioEnded, setAudioEnded] = useState(false);
  const [submitState, setSubmitState] = useState(0);

  const [audio, setAudio] = useState<any | null>(null);
  const [progress, setProgress] = useState(0);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const isRedirect = screenInfo?.screenData?.find((screens:any) => screens.screenId === 1)?.data?.redirectToMerchentSite;
  

  //const bgImage = config.themeUrl + merchant.backgroundImage
  const bgImage = giftData?.activeOccasion?.occasionColor
  const merchantId = merchant?.merchantId
  const isRecordVoice = giftData?.activeOccasion?.voiceRecord

  const gradientColors = `${giftData?.activeOccasion?.playGradiant1}, ${giftData?.activeOccasion?.playGradiant2}, ${giftData?.activeOccasion?.playGradiant3}, ${giftData?.activeOccasion?.playGradiant4}`

  const recordData = localStorage.getItem('recordData') as any;
  const recordDataString = JSON.parse(recordData);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  usePageTimeout();

  useEffect(() => {
    if (!giftData.senderfname && !giftData.sendermail && !giftData.recieverfname && !giftData.activeOccasion && !recordingData.audioBase64) {
      navigate(`/${id}/`);
    }
  }, []);

  useEffect(() => {
    if (merchant && screenInfo.screenData === undefined) {
      dispatch(getScreenInfo({merchantId: merchant?.merchantId}))
    }
  }, [merchant]);

  useEffect(()=>{
    if (screenInfo && screenInfo.screenData) {
      setScreen(screenInfo.screenData.find((screens:any) => screens.screenId === 9))
    }
  },[screenInfo && screenInfo?.screenData])

  useEffect(() => {
    
    if (giftData?.giftCode || paymentStatus.paymentStatus){      
      
    if (submitState === 2) {
      if (imageData?.fileName && recordingData?.fileName) {
        afterSubmit()
      }
    }
    else if (submitState === 1) {
      if (recordingData?.fileName) {
        afterSubmit()
      }
    }
    else if (submitState === 3) {
      if (imageData?.fileName) {
        afterSubmit()
      }
    }
    else if (submitState === 4) {
      afterSubmit()
    }
  }
  
  }, [imageData && imageData?.fileName, recordingData && recordingData?.fileName, submitState, giftData?.giftCode, paymentStatus?.paymentStatus])

  const whastappClick = () => {
    // const whatsappURL = `whatsapp://send?text=Hey there, I have a gift message for you! Use this code: ${message.token}%0a${message.qrData}`;
    const whatsappURL = `whatsapp://`;
    window.location.href = whatsappURL;
  };

  const onSubmit = () => {
    setIsLoading(true)
    setIsButtonDisabled(true);

    const imageString = giftData.imageString

    if (giftData.giftCode === null || giftData.giftCode === undefined) {
      dispatch(getPaymentStatus({ merchantId }))
    }
    
    if (imageString && (isRecordVoice && recordingData)) {
      const base64Image = imageString.split(',')[1];

      const base64Record = recordingData?.audioBase64.split(',')[1];

      dispatch(getImageUrlByUploadedImage({ File: base64Image }))
      dispatch(getAudioUrlByRecording({ File: base64Record }))
      setSubmitState(2);

    } else if (!imageString && (isRecordVoice && recordingData)) {
      const base64Record = recordingData?.audioBase64.split(',')[1]
      dispatch(getAudioUrlByRecording({ File: base64Record }))
      setSubmitState(1)
    }
    else if (imageString && !(isRecordVoice && recordingData)) {
      const base64Image = imageString.split(',')[1];
      dispatch(getImageUrlByUploadedImage({ File: base64Image }))
      setSubmitState(3)
    }
    else {
      setSubmitState(4)
    }
  }

  useEffect(() => {
    if(createMessage?.qrData){
      // whastappClick();
      navigate(`/${id}/congrats`)
    }
  },[createMessage?.qrData])


  const afterSubmit = () => {

    const message = {
      MerchantId: merchant.merchantId,
      Token: giftData.giftCode ?? paymentStatus.token.token,
      OccasionId: giftData?.activeOccasion?.occasionId,
      Sender: {
        FirstName: giftData?.senderfname ?? recordDataString?.senderfname,
        LastName: (giftData?.senderlname ?? recordDataString?.senderlname) ?? "",
        Email: giftData?.sendermail ?? recordDataString?.sendermail
      },
      Receiver: {
        FirstName: giftData?.recieverfname ?? recordDataString?.recieverfname,
        LastName: (giftData?.recieverlname ?? recordDataString?.recieverlname) ?? "",
        Email: (giftData?.recievermail ?? recordDataString?.recievermail) ?? ""
      },
      Message: {
        Text: isRecordVoice ? "" : giftData?.activeOccasion?.occasionDesc,
        Image: imageData?.fileName ?? "",
        Audio: isRecordVoice ? recordingData?.fileName : "",
        Video: ""
      }
    }


    localStorage.setItem("message", JSON.stringify(message))

    if (giftData.giftCode === "" || giftData.giftCode === undefined) {

      if (paymentStatus.paymentStatus === 1) {
        dispatch(getCreateMessage(message))
      }
      else {
        navigate(`/${id}/cart`);
      }
    } else {
      dispatch(getCreateMessage(message))
    }
  }

  const drawProgress = useCallback(() => {
    return <div className="bg-[#566CED] h-2 rounded-md absolute top-0 left-0 " style={{ width: `${progress}%` }}></div>
  }, [progress])

  const updateProgress = () => {
    const currentTime = audio.currentTime;
    const duration = audio.duration;
    const calculatedProgress = (currentTime / duration) * 100;
    setProgress(calculatedProgress);

    if (!audio.paused && !audio.ended) {
      requestAnimationFrame(updateProgress);
    }
  };

  const playClick = () => {
    audio.play();
    updateProgress();

    // Toggle the isPlaying state
    setIsPlaying(!isPlaying);

    // Reset the audioEnded state
    setAudioEnded(false);

    audio.onended = () => {
      setAudioEnded(true);
      setIsPlaying(false)
    };
  };

  const navigateText = () => {
    dispatch(setFinalUpdateMode({ text: true }));
    navigate(`/${id}/message`)
  }
  const navigateImage = () => {
    dispatch(setFinalUpdateMode({ image: true }));
    navigate(`/${id}/imageUpload`)
  }
  const navigateAudio = () => {
    navigate(`/${id}/recordvoice`)
  }

  useEffect(() => {
    const audioElement = new Audio(recordingData.audioBase64);
    setAudio(audioElement);
  }, []);

  const stopClick = () => {
    if (audio) {
      audio.pause();
      audio.currentTime = 0;

      setProgress(0);
      setIsPlaying(false);
      setAudioEnded(true);
    }
  };

// /isPlayMessage={isPlaying ? true : false}
  return (
    <Container>
      {giftData?.imageString ? ( 
      <InnerContainer bgColor={merchant?.backgroundColor} hrefLink={isRedirect ? `../${id}` : '../'}  merchantLogo={merchant?.logo} header={
        <div className={`${isPlaying ? 'hidden' : ''} flex items-center text-[12px] font-medium cursor-pointer`} onClick={() => {
          if (isRecordVoice == true) {
            navigate(`/${id}/recordvoice`);
          }
          else {
            navigate(`/${id}/imageupload`);
          }
        }}>
          <img src={backButton} className="h-[12px] w-[12px]" />
          Back
        </div>
      }>
        <>
          {isLoading ? (
            <div className='flex items-center w-full h-full'>
              <div v-if="loading" className="spinner">
                <div className="rect1"></div>
                <div className="rect2"></div>
                <div className="rect3"></div>
                <div className="rect4"></div>
                <div className="rect5"></div>
              </div>
            </div>

          ) : (
            <>
              <div className={`bg-[#E0E4FC] mt-4 rounded-md w-[90%] h-2 absolute top-0 ${isPlaying ? '' : 'hidden'}`}>
                {drawProgress()}
              </div>
              <div className={`w-full ${isPlaying ? 'hidden' : ''}`}>
              
                {/* <MainTitle size="3xl" align="center">Here is your preview, <span className="text-[#566CED]">{giftData.senderfname}!</span></MainTitle> */}
                <MainTitle size="3xl" align="center">{screen ? (
              <>
                {screen.data.title.replace("{0}", giftData.senderfname).split(giftData.senderfname).map((part: any, index: any) => (
                  <span key={index}>
                    {index > 0 && (
                      <span style={{ color: `${merchant?.textboxFocusColor ?? '#566CED'}` }}>
                        {giftData.senderfname}
                      </span>
                    )}
                    {part}
                  </span>
                ))}
              </>
            ) : ("")}</MainTitle>
              </div>

              <div className={`w-full ${isPlaying ? 'h-full' : ''}`}>
                {/* <div key={giftData.id}> */}
                <div className={`'w-full mt-5 ${isPlaying ? 'h-auto md:h-full' : ''} flex items-center justify-center`}>
                  <div id="bounced" className={`flex flex-col justify-center w-full ${isPlaying ? 'h-full' : 'min-h-[365px]'} bg-contain rounded-[32px] ${isPlaying ?  `bg-[${giftData?.activeOccasion?.playBackgroundColor}] bg-[url('${giftData?.activeOccasion?.playBackgroundUrl}')] bg-bottom bg-auto` : ` bg-cover`} bg-no-repeat  p-6`}>
                    {isPlaying && (
                      <>
                      <div className="z-20 flex justify-end cursor-pointer" onClick={stopClick}>
                        <img src={closeIcon} />
                      </div>
                      </>
                    )}
                    <div className={`flex flex-col items-center justify-center h-full gap-4 ${isPlaying ? 'md:pt-[40%] pt-[180px]' : 'pt-0'}`}>
                      

                      {isPlaying ? (
                        <>
                        <img className="absolute z-10 p-5 rounded-b-[40px] top-5" src={giftData?.imageString} />
        
                        <div className={`${isPlaying ? 'md:pt-[40%] pt-[60px]' : 'pt-0'} relative`}>
                            <img src={giftData?.activeOccasion?.ribbonImageUrl} className="relative z-10 w-full" alt="ribbon" />
                        </div>
                        
                        <div className="flex flex-col justify-between w-full h-full px-5">
                          {/* <div className="flex items-center justify-center">
                              <img src={ giftData?.imageString } className="h-[200px] w-[200px] rounded-xl" alt="uploaded-image" />
                          </div> */}
                        <div> 
                          <MainTitle size="3xl" align="center"><span className={`text-2xl font-semibold uppercase text-[${giftData?.activeOccasion?.playColor2}]`}>{giftData?.activeOccasion?.textMessage}</span><br /><span className={`text-[${giftData?.activeOccasion?.playColor1}] uppercase font-bold`}>{giftData?.recieverfname}</span></MainTitle>
                        </div>

                        <div>
                          <div className={`border-2 border-[${giftData?.activeOccasion?.playColor1}] py-0 px-5 rounded-full flex items-center`}>
                            {isPlaying ? (
                              <i className={`fa-solid fa-pause text-[${giftData?.activeOccasion?.playColor1}] text-4xl pr-2`} onClick={stopClick}></i>
                              ) : (
                              <i className={`fa-solid fa-play text-[${giftData?.activeOccasion?.playColor1}] text-4xl pr-2`} onClick={playClick}></i>
                              )
                            }
                            
                            <div className="py-3 audioWaves" style={{height: `60px`}}>
                                          {[0, 0.2, 0.4, 0.6, 0.8, 0.6, 0.4, 0.2, 0].map((delay, index) => (
                                            <span 
                                          key={index} className={`stroke ${isPlaying ? 'stroke-animation my-10' : ''}`} style={{ '--delay': `${delay}s`, background : `linear-gradient(${gradientColors})`, width: '8px', margin: '0 7px'} as CustomCSSProperties}></span>
                                          ))}
                                        </div>
                          </div>
                          <div className={`px-5 text-xs font-semibold text-center uppercase py-4 text-[${giftData?.activeOccasion?.playColor2}]`}>Tap Play button to play the message</div>
                          </div>

                          <div>
                          <MainTitle size="3xl" align="center"><span className={`text-xl font-semibold uppercase text-[${giftData?.activeOccasion?.playColor1}]`}>With Love, </span><br /><span className={`text-[${giftData?.activeOccasion?.playColor1}] uppercase font-bold`}>{giftData?.senderfname}</span></MainTitle>
                        </div>

                        <div className="text-lg text-[#d5d5d5] flex justify-center w-full">
                          <div className="flex gap-2 cursor-pointer w-fit" onClick={whastappClick}>
                          <p className="font-bold text-center">Sent Via</p>
                          {/* <img src={logo} className="h-20 mx-auto opacity-50" alt="Voicey Pix Logo" /> */}
                          {/* <p className={`logofont text-2xl font-bold text-center text-[${giftData?.activeOccasion?.playColor2}]`}>VoiceyPix</p> */}
                          <i className="flex items-center fa-brands fa-whatsapp"></i>
                          </div>
                          
                        </div>
                        </div>
                        </>
                      ) : (
                        // <div className="relative">
                        //   <img src={giftData?.activeOccasion?.occasionBg} className={`${isRecordVoice ? 'h-[400px]' : 'h-[250px]'} rounded-2xl object-cover drop-shadow-[0_0px_20px_rgba(0,0,0,0.4)]`} />
                        //   <img src={playCircle} className={`absolute ${isPlaying || !isRecordVoice ? 'hidden' : ''} top-[35%] left-[40%]`} onClick={playClick} />
                        // </div>
                        // new 2
                        <div>
                          <div className={`${isRecordVoice ? 'h-[400px]' : 'h-[250px]'} rounded-2xl object-cover drop-shadow-[0_0px_20px_rgba(0,0,0,0.4)]`}>
                          {/* <img className="z-10 p-5 rounded-b-[40px] w-full" src={giftData?.imageString} /> */}
        
                          <div className={`${isPlaying ? 'md:pt-[40%] pt-[60px]' : 'pt-0'} relative h-full`}>
                            {/* new changes */}
                              <img src={giftData?.imageString} className="relative z-10 w-full h-full rounded-b-xl" alt="ribbon" />
                              <img src={giftData?.activeOccasion?.ribbonImageUrl} className="absolute z-20 w-full bottom-10" alt="ribbon" />
                              <div className={`absolute bottom-0 z-10 w-full h-20 bg-[${giftData?.activeOccasion?.playBackgroundColor}] bg-[url('${giftData?.activeOccasion?.playBackgroundUrl}')] bg-cover`}></div>
                          </div>
                          </div>
                          {/* <img src={giftData?.activeOccasion?.occasionBg} className={`${isRecordVoice ? 'h-[400px]' : 'h-[250px]'} rounded-2xl object-cover drop-shadow-[0_0px_20px_rgba(0,0,0,0.4)]`} /> */}
                          <img src={playCircle} className={`cursor-pointer absolute ${isPlaying || !isRecordVoice ? 'hidden' : ''} top-[40%] left-[40%]`} onClick={playClick} />
                        </div>
                      )}
                      

                      <div className={`${isRecordVoice ? 'hidden' : ''} bg-white min-h-[150px] rounded-[16px] drop-shadow-[0_0px_20px_rgba(0,0,0,0.4)]`}>
                        <p className="m-4 break-words">{giftData?.activeOccasion?.occasionDesc}</p>
                      </div>
                    </div>
                  </div>
                </div>
                {/* </div> */}
                <div className={`flex flex-col justify-center mt-4 ${isPlaying ? 'hidden' : ''}`}>
                  <p className="font-semibold text-center">{screen ? screen.data.buttonTitle : ''}</p>
                  <div className="flex justify-center gap-4 mt-2">
                    {isRecordVoice ? ( 
                      <Button type="normal" btnBgColorHover={merchant?.buttonBackgroundColor} btnTextColorHover={merchant?.buttonHoverBackgroundColor} btnBorderColorHover={merchant?.buttonBackgroundColor} fullwidth={true} onClick={navigateAudio} >{screen ? screen.data.audioButton : ''}</Button>
                    ) : (
                      <Button type="normal" btnBgColorHover={merchant?.buttonBackgroundColor} btnTextColorHover={merchant?.buttonHoverBackgroundColor} btnBorderColorHover={merchant?.buttonBackgroundColor} fullwidth={true} onClick={navigateText} >{screen ? screen.data.textButton : ''}</Button>
                    )}
                      <Button type="normal" btnBgColorHover={merchant?.buttonBackgroundColor} btnTextColorHover={merchant?.buttonHoverBackgroundColor} btnBorderColorHover={merchant?.buttonBackgroundColor} fullwidth={true} onClick={navigateImage} >{screen ? screen.data.imageButton : ''}</Button>
                  </div>
                </div>
              </div>

              <div className={`w-full mt-2 ${isPlaying ? 'hidden' : ''}`}>
                <div className="flex flex-col justify-center">
                  <Button type='base' btnTextColor={merchant?.buttonTextColor} btnBgColor={merchant?.buttonBackgroundColor} btnBgColorHover={merchant?.buttonHoverBackgroundColor} btnBorderColor={merchant?.buttonBorderColor} btnTextColorHover={merchant?.buttonHoverTextColor} btnBorderColorHover={merchant?.buttonHoverBorderColor} fullwidth={true} onClick={onSubmit} disabled={isButtonDisabled}>{screen ? screen.data.continueButton : ''}</Button>
                </div>
              </div>
            </>
          )}

        </>
      </InnerContainer>
      ) : (
        // Without image Upload
      <div>
        <InnerContainer bgColor={merchant?.backgroundColor} hrefLink={isRedirect ? `../${id}` : '../'} merchantLogo={merchant?.logo} header={
        <div className={`${isPlaying ? 'hidden' : ''} flex items-center text-[12px] font-medium cursor-pointer`} onClick={() => {
          if (isRecordVoice == true) {
            navigate(`/${id}/recordvoice`);
          }
          else {
            navigate(`/${id}/imageupload`);
          }
        }}>
          <img src={backButton} className="h-[12px] w-[12px]" />
          Back
        </div>
      }>
        <>
          {isLoading ? (
            <div className='flex items-center w-full h-full'>
              <div v-if="loading" className="spinner">
                <div className="rect1"></div>
                <div className="rect2"></div>
                <div className="rect3"></div>
                <div className="rect4"></div>
                <div className="rect5"></div>
              </div>
            </div>

          ) : (
            <>
              <div className={`bg-[#E0E4FC] mt-4 rounded-md w-[90%] h-2 absolute top-0 ${isPlaying ? '' : 'hidden'}`}>
                {drawProgress()}
              </div>
              <div className={`w-full ${isPlaying ? 'hidden' : ''}`}>
              
                {/* <MainTitle size="3xl" align="center">Here is your preview, <span className="text-[#566CED]">{giftData.senderfname}!</span></MainTitle> */}
                <MainTitle size="3xl" align="center">{screen ? (
              <>
                {screen.data.title.replace("{0}", giftData.senderfname).split(giftData.senderfname).map((part: any, index: any) => (
                  <span key={index}>
                    {index > 0 && (
                      <span style={{ color: `${merchant?.textboxFocusColor ?? '#566CED'}` }}>
                        {giftData.senderfname}
                      </span>
                    )}
                    {part}
                  </span>
                ))}
              </>
            ) : ("")}</MainTitle>
              </div>

              <div className={`w-full ${isPlaying ? 'h-full' : ''}`}>
                {/* <div key={giftData.id}> */}
                <div className={`'w-full mt-5 ${isPlaying ? 'h-auto md:h-full' : ''} flex items-center justify-center`}>
                  <div id="bounced" className={`flex flex-col justify-center w-full ${isPlaying ? 'h-screen' : 'min-h-[365px]'} rounded-[32px] ${isPlaying ? ` bg-[url('${giftData?.activeOccasion?.playBackgroundUrl}')] bg-cover bg-[${giftData?.activeOccasion?.playBackgroundColor}]` : ` bg-cover`} bg-no-repeat  p-6`}>
                    {isPlaying && (
                      <div className="flex justify-end cursor-pointer" onClick={stopClick}>
                        <img src={closeIcon} />
                      </div>
                    )}
                    <div className={`relative flex flex-col items-center justify-center w-full h-full gap-4`}>
                      

                      {isPlaying ? (
                        <>
                        <div className="flex flex-col justify-between w-full h-full">
                        <div className={`relative`}>
                            <img src={giftData?.activeOccasion?.ribbonImageUrl} className="relative z-10 w-full" alt="ribbon" />
                        </div>
                        <div> 
                          <MainTitle size="3xl" align="center"><span className={`text-2xl font-semibold uppercase text-[${giftData?.activeOccasion?.playColor2}]`}>{giftData?.activeOccasion?.textMessage}</span><br /><span className={`text-[${giftData?.activeOccasion?.playColor1}] uppercase font-bold`}>Dear {giftData?.recieverfname}</span></MainTitle>
                        </div>

                        <div>
                          <div className={`border-2 border-[${giftData?.activeOccasion?.playColor1}] py-0 px-5 rounded-full flex items-center`}>
                            {isPlaying ? (
                              <i className={`fa-solid fa-pause text-[${giftData?.activeOccasion?.playColor1}] text-4xl pr-2`} onClick={stopClick}></i>
                              ) : (
                              <i className={`fa-solid fa-play text-[${giftData?.activeOccasion?.playColor1}] text-4xl pr-2`} onClick={playClick}></i>
                              )
                            }
                            
                            <div className="py-3 audioWaves" style={{height: `60px`}}>
                                          {[0, 0.2, 0.4, 0.6, 0.8, 0.6, 0.4, 0.2, 0].map((delay, index) => (
                                            <span 
                                          key={index} className={`stroke ${isPlaying ? 'stroke-animation my-10' : ''}`} style={{ '--delay': `${delay}s`, background : `linear-gradient(${gradientColors})`, width: '8px', margin: '0 7px'} as CustomCSSProperties}></span>
                                          ))}
                                        </div>
                          </div>
                          <div className={`px-5 text-xs font-semibold text-center uppercase py-4 text-[${giftData?.activeOccasion?.playColor2}]`}>Tap Play button to play the message</div>
                          </div>

                          <div>
                          <MainTitle size="3xl" align="center"><span className={`text-xl font-semibold uppercase text-[${giftData?.activeOccasion?.playColor1}]`}>With Love, </span><br /><span className={`text-[${giftData?.activeOccasion?.playColor1}] uppercase font-bold`}>{giftData?.senderfname}</span></MainTitle>
                        </div>

                        <div className="text-lg text-[#d5d5d5] flex justify-center w-full">
                          <div className="flex gap-2 cursor-pointer w-fit" onClick={whastappClick}>
                          <p className="font-bold text-center">Sent Via</p>
                          {/* <img src={logo} className="h-20 mx-auto opacity-50" alt="Voicey Pix Logo" /> */}
                          {/* <p className={`logofont text-2xl font-bold text-center text-[${giftData?.activeOccasion?.playColor2}]`}>VoiceyPix</p> */}
                          <i className="flex items-center fa-brands fa-whatsapp"></i>
                          </div>
                          
                        </div>
                        </div>
                        </>
                      ) : (
                        <div>
                          <div className={`${isRecordVoice ? 'h-[400px]' : 'h-[250px]'} rounded-2xl object-cover drop-shadow-[0_0px_20px_rgba(0,0,0,0.4)]`}>
                          {/* <img className="z-10 p-5 rounded-b-[40px] w-full" src={giftData?.imageString} /> */}
        
                          <div className={`${isPlaying ? 'md:pt-[40%] pt-[60px]' : 'pt-0'} relative h-full`}>
                            {/* new changes */}
                              <img src={giftData?.activeOccasion?.playBackgroundUrl} className="relative z-10 w-full h-full" alt="ribbon" />
                              <img src={giftData?.activeOccasion?.ribbonImageUrl} className="absolute z-20 w-full bottom-10" alt="ribbon" />
                              <div className={`absolute bottom-0 z-10 w-full h-20 bg-[${giftData?.activeOccasion?.playBackgroundColor}] bg-[url('${giftData?.activeOccasion?.playBackgroundUrl}')] bg-cover`}></div>
                          </div>
                          </div>
                          {/* <img src={giftData?.activeOccasion?.occasionBg} className={`${isRecordVoice ? 'h-[400px]' : 'h-[250px]'} rounded-2xl object-cover drop-shadow-[0_0px_20px_rgba(0,0,0,0.4)]`} /> */}
                          <img src={playCircle} className={`cursor-pointer absolute ${isPlaying || !isRecordVoice ? 'hidden' : ''} top-[40%] left-[40%]`} onClick={playClick} />
                        </div>
                      )}
                      

                      <div className={`${isRecordVoice ? 'hidden' : ''} bg-white min-h-[150px] rounded-[16px] drop-shadow-[0_0px_20px_rgba(0,0,0,0.4)]`}>
                        <p className="m-4 break-words">{giftData?.activeOccasion?.occasionDesc}</p>
                      </div>
                    </div>
                  </div>
                </div>
                {/* </div> */}
                <div className={`flex flex-col justify-center mt-4 ${isPlaying ? 'hidden' : ''}`}>
                  <p className="font-semibold text-center">{screen ? screen.data.buttonTitle : ''}</p>
                  <div className="flex justify-center gap-4 mt-2">
                    {isRecordVoice ? ( 
                      <Button type="normal" btnTextColor={merchant?.buttonHoverTextColor} btnBgColor={merchant?.buttonHoverBackgroundColor} btnBgColorHover={merchant?.buttonBackgroundColor} btnTextColorHover={merchant?.buttonTextColor} btnBorderColor={merchant?.buttonHoverBorderColor} btnBorderColorHover={merchant?.buttonBorderColor} onClick={navigateAudio} >{screen ? screen.data.audioButton : ''}</Button>
                    ) : (
                      <Button type="normal" btnTextColor={merchant?.buttonHoverTextColor} btnBgColor={merchant?.buttonHoverBackgroundColor} btnBgColorHover={merchant?.buttonBackgroundColor} btnTextColorHover={merchant?.buttonTextColor} btnBorderColor={merchant?.buttonHoverBorderColor} btnBorderColorHover={merchant?.buttonBorderColor} onClick={navigateText} >{screen ? screen.data.textButton : ''}</Button>
                    )}
                      <Button type="normal" btnTextColor={merchant?.buttonHoverTextColor} btnBgColor={merchant?.buttonHoverBackgroundColor} btnBgColorHover={merchant?.buttonBackgroundColor} btnTextColorHover={merchant?.buttonTextColor} btnBorderColor={merchant?.buttonHoverBorderColor} btnBorderColorHover={merchant?.buttonBorderColor} onClick={navigateImage} >{screen ? screen.data.imageButton : ''}</Button>
                  </div>
                </div>
              </div>

              <div className={`w-full mt-2 ${isPlaying ? 'hidden' : ''}`}>
                <div className="flex flex-col justify-center">
                  <Button type='base' btnTextColor={merchant?.buttonTextColor} btnBgColor={merchant?.buttonBackgroundColor} btnBgColorHover={merchant?.buttonHoverBackgroundColor} btnBorderColor={merchant?.buttonBorderColor} btnTextColorHover={merchant?.buttonHoverTextColor} btnBorderColorHover={merchant?.buttonHoverBorderColor} fullwidth={true} onClick={onSubmit} disabled={isButtonDisabled}>{screen ? screen.data.continueButton : ''}</Button>
                </div>
              </div>
            </>
          )}

        </>
      </InnerContainer>
      </div>
    )}
    </Container>
  );
}

export default Preview;