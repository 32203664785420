import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Helmet } from 'react-helmet-async';
import { MainTitle, Container, InnerContainer } from "app-premitives/index";
import { useDispatch, useSelector } from "react-redux";
import { selectMerchant, selectConfigs, selectMessage, selectTheme, selectScreenInfo } from 'app-root/pages/merchant/merchant.selector'
import voiceyPixLogo from 'app-root/assets/images/Recqordlogo.png'
import useMerchantInfo from "app-core/theme/theme.hook";
import usePageTimeout from "app-root/core/theme/page-timeout.hook";
import { getScreenInfo } from "../merchant.slice";

interface CustomCSSProperties extends React.CSSProperties {
  '--delay'?: string;
}

const PlayMessage: React.FC = () => {
  const merchant = useSelector(selectMerchant);
  const config = useSelector(selectConfigs);
  const message = useSelector(selectMessage);
  const { id } = useParams();
  const occasionTheme = useSelector(selectTheme)

  const [isPlaying, setIsPlaying] = useState(false);
  const [audioEnded, setAudioEnded] = useState(false);
  const [notPlayed, setNotPlayed] = useState(true)

  const [audio, setAudio] = useState<any | null>(null);
  const [progress, setProgress] = useState(0);
  const gradientColors = `${message?.result?.occasion.playGradiant1}, ${message?.result?.occasion.playGradiant2}, ${message?.result?.occasion.playGradiant3}, ${message?.result?.occasion.playGradiant4}`

  const navigate = useNavigate();
  const logo = config?.merchentUrl && merchant?.logo ? config?.merchentUrl + merchant?.logo : voiceyPixLogo;
  const screenInfo = useSelector(selectScreenInfo);
  const [screen, setScreen] = useState(null) as any
  const isRedirect = screenInfo?.screenData?.find((screens:any) => screens.screenId === 1)?.data?.redirectToMerchentSite;
  
  //const bgImage = config.themeUrl+merchant.backgroundImage
  //const bgImage = message.result.occasion.backgroundUrl
  const dispatch = useDispatch();

  const updateProgress = () => {
    const currentTime = audio.currentTime;
    const duration = audio.duration;
    const calculatedProgress = (currentTime / duration) * 100;
    setProgress(calculatedProgress);

    if (!audio.paused && !audio.ended) {
      requestAnimationFrame(updateProgress);
    }
  };

  useEffect(() => {
    if (merchant && screenInfo.screenData === undefined) {
      dispatch(getScreenInfo({merchantId: merchant?.merchantId}))
    }
  }, [merchant]);

  const whastappClick = () => {
    const whatsappURL = `whatsapp://`;
    window.location.href = whatsappURL;
  };

  const playClick = () => {
    // Check if the audio element exists
    if (audio) {
      setNotPlayed(false)
      audio.play();
  
      updateProgress();
  
      // Toggle the isPlaying state
      setIsPlaying(!isPlaying);
  
      // Reset the audioEnded state
      setAudioEnded(false);
  
      audio.onended = () => {
        setAudioEnded(true);
        setIsPlaying(false);
      };
    }
  };

  usePageTimeout();

useEffect(() => {
  const audioElement = new Audio(message?.result?.message?.audio);
  setAudio(audioElement);
}, [message]);

useEffect(() => {
  if (screenInfo && screenInfo.screenData) {
    setScreen(screenInfo.screenData.find((screens: any) => screens.screenId === 102))
  }
}, [screenInfo && screenInfo?.screenData])

const stopClick = () => {
  if (audio) {
    audio.pause();
    audio.currentTime = 0;

    setProgress(0);
    setIsPlaying(false);
    setAudioEnded(true); 
  }
};

  return (
    <>

    <Helmet>
      <meta name="description" key="description" content={message?.result?.occasion.occasionDesc} />
      <meta property="og:title" key="og:title" content="Voicey Pix" />
      <meta property="og:locale" key="og:locale" content="en_US" />
      <meta charSet="utf-8" />
      <meta property="og:type" key="og:type" content="website" />
      <meta property="og:url" content={message.qrData} />
      <meta property="og:image" content={message?.result?.occasion.occasionBg} />
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="628" />

    </Helmet>
    {/* This is for the message with the image */}
      <Container>
      {message?.result?.message?.image ? (
                  <InnerContainer playbgImage={true} merchantLogo={logo} hrefLink={isRedirect ? `${merchant?.siteUrl}` : '../'} moreStyle="bg-bottom" isPlayMessage={true} bgColor={message?.result?.occasion.playBackgroundColor} bgImage={message?.result?.occasion.playBackgroundUrl} isVisibility={false}>
        <>
        <img className="absolute top-0 rounded-b-2xl md:h-[500px]" src={message?.result?.message?.image} />
        
        <div className="md:mt-[66%] mt-[40%] relative">
            <img src={message?.result?.occasion.ribbonImageUrl} className="relative z-10 w-full" alt="ribbon" />
        </div>
        
        <div className={`w-full`}>
                <MainTitle size="3xl" align="center">
                {/* <span className={`text-2xl font-semibold uppercase text-[${message?.result?.occasion.playColor2}]`}>{message?.result?.occasion.message}</span><br /> */}
                  <span className={`text-[${message?.result?.occasion.playColor1}] uppercase font-bold`}>Dear {message?.result?.receiver?.firstName}</span></MainTitle>
              </div>

      {message?.result?.message.audio != null ? (
        <div>
        <div className={`border-2 border-[${message?.result?.occasion.playColor1}] py-0 px-5 rounded-full flex items-center`}>
          {isPlaying ? (
            <i className={`fa-solid fa-pause cursor-pointer text-[${message?.result?.occasion.playColor1}] text-4xl pr-2`} onClick={stopClick}></i>
            ) : (
            <i className={`fa-solid fa-play cursor-pointer text-[${message?.result?.occasion.playColor1}] text-4xl pr-2`} onClick={playClick}></i>
            )
          }
          
          <div className="py-3 audioWaves" style={{height: `60px`}}>
                        {[0, 0.2, 0.4, 0.6, 0.8, 0.6, 0.4, 0.2, 0].map((delay, index) => (
                          <span 
                         key={index} className={`stroke ${isPlaying ? 'stroke-animation my-10' : ''}`} style={{ '--delay': `${delay}s`, background : `linear-gradient(${gradientColors})`} as CustomCSSProperties}></span>
                        ))}
                      </div>
        </div>
        <div className={`px-5 text-xs font-semibold text-center uppercase py-4 text-[${message?.result?.occasion.playColor1}]`}>Tap Play button to play the message</div>
        </div>
) : (
    <div className="w-full">
      <div className="bg-white min-h-[150px] rounded-[16px] drop-shadow-[0_0px_20px_rgba(0,0,0,0.4)]">
        <p className="p-4 break-words">{message?.result?.message.text}</p></div>
    </div>
    )
}
        
        <div>
          <MainTitle size="3xl" align="center"><span className={`text-xl font-semibold uppercase text-[${message?.result?.occasion.playColor1}]`}> With love, </span><br /><span className={`text-[${message?.result?.occasion.playColor1}] uppercase font-bold`}>{message?.result?.sender?.firstName}</span></MainTitle>
        </div>

        {/* <div className="text-lg">
                          <p className="font-bold text-center text-[#d5d5d5]">Thanks Via</p>
                          <p onClick={() => { navigate(`/${id}/`) }} className={`cursor-pointer logofont text-2xl font-bold text-center text-[${message?.result?.occasion.playColor2}]`}>VoiceyPix</p>
                        </div> */}
                        <div className="text-lg text-[#d5d5d5] flex justify-center w-full">
                          <div className="flex gap-2 cursor-pointer w-fit" onClick={whastappClick}>
                          <p className="font-bold text-center">                          
                          {screen?.data?.sendVia.replace("{0}", message?.result?.sender?.firstName).split(message?.result?.sender?.firstName).map((part: any, index: any) => (
                  <span key={index}>
                    {index > 0 && (
                        message?.result?.sender?.firstName
                    )}
                    {part}
                  </span>
                ))}
                          </p>
                          {/* <img src={logo} className="h-20 mx-auto opacity-50" alt="Voicey Pix Logo" /> */}
                          {/* <p className={`logofont text-2xl font-bold text-center text-[${giftData?.activeOccasion?.playColor2}]`}>VoiceyPix</p> */}
                          <i className="flex items-center fa-brands fa-whatsapp"></i>
                          </div>
                          
                        </div>
       
        </>

      </InnerContainer>
      ) : (

        //This is for the message without image
        
        <InnerContainer moreStyle="bg-cover bg-no-repeat" hrefLink={isRedirect ? `${merchant?.siteUrl}` : '../'} isPlayMessage={true} bgColor={message?.result?.occasion.playBackgroundColor} bgImage={message?.result?.occasion?.playBackgroundUrl} isVisibility={false}>
        <>
        {/* <img className="absolute top-0 rounded-b-2xl md:h-[500px]" src={message?.result?.occasion?.playBackgroundUrl} /> */}

        <div className="relative">
            <img src={message?.result?.occasion.ribbonImageUrl} className="relative z-10 w-full" alt="ribbon" />
        </div>
          
        <div className={`w-full`}>
                <MainTitle size="3xl" align="center">
                {/* <span className={`text-2xl font-semibold uppercase text-[${message?.result?.occasion.playColor2}]`}>{message?.result?.occasion.message}</span><br /> */}
                  <span className={`text-[${message?.result?.occasion.playColor1}] uppercase font-bold`}>Dear {message?.result?.receiver?.firstName}</span></MainTitle>
              </div>

      {message?.result?.message.audio != null ? (
        <div>
        <div className={`border-2 border-[${message?.result?.occasion.playColor1}] py-0 px-5 rounded-full flex items-center`}>
          {isPlaying ? (
            <i className={`fa-solid fa-pause cursor-pointer text-[${message?.result?.occasion.playColor1}] text-4xl pr-2`} onClick={stopClick}></i>
            ) : (
            <i className={`fa-solid fa-play cursor-pointer text-[${message?.result?.occasion.playColor1}] text-4xl pr-2`} onClick={playClick}></i>
            )
          }
          
          <div className="py-3 audioWaves" style={{height: `60px`}}>
                        {[0, 0.2, 0.4, 0.6, 0.8, 0.6, 0.4, 0.2, 0].map((delay, index) => (
                          <span 
                         key={index} className={`stroke ${isPlaying ? 'stroke-animation my-10' : ''}`} style={{ '--delay': `${delay}s`, background : `linear-gradient(${gradientColors})`} as CustomCSSProperties}></span>
                        ))}
                      </div>
        </div>
        <div className={`px-5 text-xs font-semibold text-center uppercase py-4 text-[${message?.result?.occasion.playColor1}]`}>Tap Play button to play the message</div>
        </div>
) : (
    <div className="w-full">
      <div className="bg-white min-h-[150px] rounded-[16px] drop-shadow-[0_0px_20px_rgba(0,0,0,0.4)]">
        <p className="p-4 break-words">{message?.result?.message.text}</p></div>
    </div>
    )
}
        
        <div>
          <MainTitle size="3xl" align="center"><span className={`text-xl font-semibold uppercase text-[${message?.result?.occasion.playColor1}]`}> With love, </span><br /><span className={`text-[${message?.result?.occasion.playColor1}] uppercase font-bold`}>{message?.result?.sender?.firstName}</span></MainTitle>
        </div>

        <div className="text-lg text-[#d5d5d5] flex justify-center w-full">
                          <div className="flex gap-2 cursor-pointer w-fit" onClick={whastappClick}>
                          <p className="font-bold text-center">
                          {screen?.data?.sendVia.replace("{0}", message?.result?.sender?.firstName).split(message?.result?.sender?.firstName).map((part: any, index: any) => (
                  <span key={index}>
                    {index > 0 && (
                        message?.result?.sender?.firstName
                    )}
                    {part}
                  </span>
                ))}
                          </p>
                          {/* <img src={logo} className="h-20 mx-auto opacity-50" alt="Voicey Pix Logo" /> */}
                          {/* <p className={`logofont text-2xl font-bold text-center text-[${giftData?.activeOccasion?.playColor2}]`}>VoiceyPix</p> */}
                          <i className="flex items-center fa-brands fa-whatsapp"></i>
                          </div>
                          
                        </div>
       
        </>
      </InnerContainer>

      )}
    </Container>
    </>
  );
}

export default PlayMessage;