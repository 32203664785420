import React from 'react';
import type { ContainerProp } from "app-premitives/premitives.type";

export const InnerContainer = ({ children, className, header, isVisibility = true, bgColor, bgImage, isPlayMessage, moreStyle, playbgImage, merchantLogo, hrefLink }: ContainerProp) => {
  return (
    <>
        {isPlayMessage ? (
          <div className={`w-screen md:w-[400px] h-[100svh] overflow-y-auto 2xl:h-[900px] flex flex-col bg-[${bgColor}] bg-cover md:shadow-lg md:shadow-slate-500 md:overflow-hidden`}>
       
          <div className={`w-full relative flex gap-3 flex-col bg-contain bg-[url('${bgImage}')] ${moreStyle} bg-contain justify-between items-center h-full px-5 md:px-5 pb-[30px] ${bgImage && playbgImage ? 'pt-[50%]' : 'pt-[15%]'}`}>
            {children}
            {/* <div className="relative h-screen">
          <div className="absolute bottom-0 left-0 w-full h-20 bottom-corner-mask" style={{backgroundImage: `linear-gradient(to bottom left, transparent 50%, ${bgColor} 50%), linear-gradient(to bottom right, transparent 50%, ${bgColor} 50%)`}}></div>
</div> */}

          </div>
          
          
        </div>
       
) : (
<div className={`w-screen md:w-[400px] h-[100svh] overflow-y-auto 2xl:h-[900px] bg-[${bgColor}] flex flex-col  ${className} bg-[url(${bgImage})] md:shadow-lg md:shadow-slate-500 md:overflow-hidden`}>
      {header}
      <div className={`flex justify-center`}>
        {isVisibility && merchantLogo ? (
          <img className='h-12 cursor-pointer' src={merchantLogo} alt="Logo" onClick={() => { window.location.href = `${hrefLink}` }} />
        ) : null}
      </div>
      <div className={`w-full relative flex gap-3 flex-col bg-[${bgColor}] justify-between items-center h-full px-5 md:px-5 pt-[20px] pb-[30px]`}>
        {children}
      </div>
    </div>
) }

    </>
  );
};
