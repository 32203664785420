import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { resetState } from 'app-root/pages/merchant/merchant.slice';
import { inValidMerchant, selectMerchant, selectReady } from 'app-root/pages/merchant/merchant.selector';

const usePageTimeout = (timeoutDuration = 5 * 60 * 1000) => { // Default is 5 minutes
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const [shouldNavigate, setShouldNavigate] = useState(false);
  const merchantData = useSelector(selectMerchant);
  const ready = useSelector(selectReady);
  const isInvalidMerchant = useSelector(inValidMerchant);

  const lowerCaseId = id?.toLowerCase();

  useEffect(() => {
    const timer = setTimeout(() => {
      setShouldNavigate(true);
    }, timeoutDuration);

    return () => clearTimeout(timer);
  }, [dispatch, timeoutDuration]);

  useEffect(() => {
    if (shouldNavigate) {
      localStorage.clear();
      navigate(`/${id}`);
      window.location.reload();
    }
  }, [shouldNavigate, navigate, id]);

  useEffect(() => {
    if (isInvalidMerchant) {
      localStorage.clear();
      navigate('/aboutus');
      window.location.reload();
    }
  }, [isInvalidMerchant, navigate]);

  useEffect(() => {
    if(!isInvalidMerchant && (merchantData?.siteName !== undefined) && (lowerCaseId !== merchantData?.siteName)){
      setShouldNavigate(true)
    }
  }, [lowerCaseId, id, dispatch, merchantData && merchantData.siteName]);
  

};

export default usePageTimeout;