import { MainTitle, Paragraph, Button, SystemTitle, List } from "app-root/components/premitives";
import React from "react";
import pandaImage from "app-assets/images/winking-panda.png"
import heartImage from "app-assets/images/about-heart.svg"
import giftboxImage from "app-assets/images/about-gift.svg"
import qrImage from "app-assets/images/about-qr.png"
import shieldImage from "app-assets/images/about-priv.svg"

const WhyRecqord = () => {
  return (
    <div className="md:flex md:py-[128px] py-[60px] md:px-0 px-4 justify-between">
      <div className="flex flex-col justify-center gap-16">
        <div className="flex flex-col justify-center w-1/2 gap-6 m-auto text-center">
          <SystemTitle>Why Recqord</SystemTitle>
          <MainTitle size="5xl" weight="font-bold">What Sets Us Apart</MainTitle>
        </div>
        <div className="flex flex-col items-center justify-start gap-10 md:flex-row">
          <div className="md:w-[25%]">
            <img src={heartImage} alt="Image of a heart" />
          </div>
          <div className="md:w-[50%]">
            <MainTitle color="#2253B7" weight="font-bold" className="mb-4 sueEllenFont" align="left">VOICE IS THE HEART</MainTitle>
          <Paragraph size="lg" align="left" color="#767676" weight="font-semibold">
            We understand that your voice carries the essence of your emotions. Recqord is designed to emphasize the 
            power of voice messaging, adding a personal touch to your conversations.
          </Paragraph>
          </div>
          <div className="md:w-[25%]"></div>
        </div>


        <div className="flex flex-col-reverse items-center justify-end gap-10 md:flex-row">  
          <div className="md:w-[25%]"></div>
          <div className="md:w-[50%]">
            <MainTitle color="#2253B7" weight="font-bold" className="mb-4 sueEllenFont" align="right">GIFTS WITH A PURPOSE</MainTitle>
          <Paragraph size="lg" align="right" color="#767676" weight="font-semibold">
            We understand that your voice carries the essence of your emotions. Recqord is designed 
            to emphasize the power of voice messaging, adding a personal touch to your conversations.
          </Paragraph>
          </div>
          <div className="md:w-[25%]">
            <img src={giftboxImage} alt="Image of a giftbox" />
          </div>
        </div>

        <div className="flex flex-col items-center justify-start gap-10 md:flex-row">
          <div className="md:w-[25%]">
            <img src={qrImage} alt="Image of a qr code" />
          </div>
          <div className="md:w-[50%]">
            <MainTitle color="#2253B7" weight="font-bold" className="mb-4 sueEllenFont" align="left">QR CODE MAGIC</MainTitle>
          <Paragraph size="lg" align="left" color="#767676" weight="font-semibold">
          Our unique QR code feature makes sharing and receiving messages a breeze. 
          Scan the code, and let the magic unfold as your heartfelt voice message is played</Paragraph>

          <Paragraph size="lg" align="left" color="#767676" weight="font-semibold" classNames="mt-3">
          We prioritize your user experience. Our user-friendly interface ensures that sending and receiving messages is intuitive, 
          creating a seamless and enjoyable interaction.
          </Paragraph>
          </div>
          <div className="md:w-[25%]"></div>
        </div>

        <div className="flex flex-col-reverse items-center justify-end gap-10 md:flex-row">  
          <div className="md:w-[25%]"></div>
          <div className="md:w-[50%]">
            <MainTitle color="#2253B7" weight="font-bold" className="mb-4 sueEllenFont" align="right">COMMITTED TO PRIVACY</MainTitle>
          <Paragraph size="lg" align="right" color="#767676" weight="font-semibold">
          Your privacy matters to us. Recqord is committed to providing a secure platform where you can express 
          yourself without worries. We store all the message in a high secure encrypted way! Further, Audit 
          process always running for your data safety.
          </Paragraph>
          </div>
          <div className="md:w-[25%]">
            <img src={shieldImage} alt="" />
          </div>
        </div>

      </div>
    </div>
  );
};

export default WhyRecqord;
