import React from "react";
import Header from "app-components/header";

const Pricing = () => {
  return (
    <div>
      <Header />
      Pricing
    </div>
  );
};

export default Pricing;
