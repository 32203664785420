import React from "react";
import { Form } from "antd";

const onFinish = (values: any) => {
  console.log("Success:", values);
};

const onFinishFailed = (errorInfo: any) => {
  console.log("Failed:", errorInfo);
};

export const ModelForm = ({ children, model, onFinish, ...rest }: any) => {
  return (
    <Form
      layout="vertical"
      style={{ width: '100%', fontFamily: 'Montserrat', fontSize: '16px' }}
      initialValues={model}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
      requiredMark={false}
      labelCol={{ span: 8, style: { fontWeight: '600', fontSize: '16px', fontFamily: 'Montserrat' } }}
      {...rest}
    >
      {children}
    </Form>
  );
};
