import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { MainTitle, Container, ModelForm, InnerContainer, LargeButton, LinkButton, InputField, Button, Info } from "app-premitives/index";
import { Form, Input } from "antd";
import { useDispatch, useSelector } from "react-redux";
import useMerchantInfo from "app-core/theme/theme.hook";
import { getOccassion, getScreenInfo, setGiftdata } from "app-pages/merchant/merchant.slice";
import backButton from 'app-assets/images/caret-left-solid.svg'
import { selectConfigs, selectGiftData, selectMerchant, selectScreenInfo } from "app-pages/merchant/merchant.selector"
import usePageTimeout from "app-root/core/theme/page-timeout.hook";

const Receiverdata: React.FC = () => {
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };

  const giftData = useSelector(selectGiftData);
  const screenInfo = useSelector(selectScreenInfo);
  const [screen, setScreen] = useState(null) as any
  const { id } = useParams();

  const navigate = useNavigate();

  const dispatch = useDispatch();
  const [formData, setFormData] = useState({});
  const config = useSelector(selectConfigs)
  const merchant = useSelector(selectMerchant);
  const isRedirect = screenInfo?.screenData?.find((screens:any) => screens.screenId === 1)?.data?.redirectToMerchentSite;
  const merchantTypeId = merchant?.typeId;

  usePageTimeout();

  useEffect(()=>{
      if(!giftData.senderfname && !giftData.sendermail){
        navigate(`/${id}/`)
      }
    
  },[])

  useEffect(() => {
    console.log('screen info', screenInfo.screenData)
    if (merchant && screenInfo.screenData === undefined) {
      dispatch(getScreenInfo({merchantId: merchant?.merchantId}))
    }
  }, [merchant]);

  useEffect(() => {
    if (screenInfo && screenInfo.screenData) {
      setScreen(screenInfo.screenData.find((screens: any) => screens.screenId === 4))
    }
  }, [screenInfo && screenInfo?.screenData])

  const model = {
    recieverfname: giftData.recieverfname ?? "",
    recieverlname: giftData.recieverlname ?? "",
    recievermail: giftData.recievermail ?? ""
  }

  const handleInputChange = (fieldName: string, value: any) => {
    setFormData({
      ...formData,
      [fieldName]: value,
    });
  };


  const onFinish = () => {
    dispatch(setGiftdata({ ...model, ...formData }));
    navigate(`/${id}/occasion`)
  };

  const [form] = Form.useForm();

  return (
    <Container>
      <InnerContainer bgColor={merchant?.backgroundColor} hrefLink={isRedirect ? `../${id}` : '../'} merchantLogo={merchant?.logo} header={
        <div className="flex items-center text-[12px] font-medium cursor-pointer" onClick={() => {
          if(merchantTypeId === 2){
            dispatch(setGiftdata({ ...model, ...formData }));
          }
          navigate(`/${id}/senderdata`)
        }}>
          <img src={backButton} className="h-[12px] w-[12px]" />
          Back
        </div>
      }>
        <>
          {/* ${giftData.senderfname} */}
          <div className="w-full">
            {/* <MainTitle size="3xl" align="center">Hi <span className="text-[#566CED]">{giftData.senderfname}</span>!</MainTitle> */}
            <MainTitle size="3xl" align="center">{screen ? (
              <>
                {screen.data.title.replace("{0}", giftData.senderfname).split(giftData.senderfname).map((part: any, index: any) => (
                  <span key={index}>
                    {index > 0 && (
                      <span style={{ color: `${merchant?.textboxFocusColor ?? '#566CED'}` }}>
                        {giftData.senderfname}
                      </span>
                    )}
                    {part}
                  </span>
                ))}
              </>
            ) : ("")}</MainTitle>
            <MainTitle size="3xl" align='center'>{screen ? screen.data.title2 : ''}</MainTitle>
            <div className="mt-6">
              <ModelForm form={form} model={model} onFinish={onFinish}>
                <Form.Item
                  label={screen ? screen.data.firstName : ''}
                  name="recieverfname"
                  rules={[
                    {
                      required: true,
                      message: "Please input the First Name!",
                    },
                  ]}
                >
                  <InputField
                    borderColor={merchant?.textboxFocusColor}
                    hoverBorderColor={merchant?.textboxHoverColor}
                    bgColor={merchant?.textboxBackgroundColor}
                    hint={screen ? screen.data.firstName : ''}
                    onChange={(e: any) => handleInputChange("recieverfname", e.target.value)}
                  />
                </Form.Item>
                <Form.Item
                  label={screen ? screen.data.lastName : ''}
                  name="recieverlname"
                  rules={[
                    {
                      required: false,
                      message: "Please input the Last Name!",
                    },
                  ]}
                >
                  <InputField
                    borderColor={merchant?.textboxFocusColor}
                    hoverBorderColor={merchant?.textboxHoverColor}
                    bgColor={merchant?.textboxBackgroundColor}
                    hint={screen ? screen.data.lastName : ''}
                    onChange={(e: any) => handleInputChange("recieverlname", e.target.value)}
                  />
                </Form.Item>
                <Form.Item
                  label={screen ? screen.data.mailAddress : ''}
                  name="recievermail"
                  rules={[
                    {
                      type: "email",
                      message: "The input is not a valid E-mail!",
                    },
                    {
                      required: false,
                      message: "Please input the email address!",
                    },
                  ]}
                >
                  <InputField
                    borderColor={merchant?.textboxFocusColor}
                    hoverBorderColor={merchant?.textboxHoverColor}
                    bgColor={merchant?.textboxBackgroundColor}
                    hint={screen ? screen.data.mailAddress : ''}
                    onChange={(e: any) => handleInputChange("recievermail", e.target.value)}
                  />
                </Form.Item>
              </ModelForm>
            </div>
          </div>


          <div className='w-full'>
            <div className="flex flex-col justify-center">
              <Button type='base' btnTextColor={merchant?.buttonTextColor} btnBgColor={merchant?.buttonBackgroundColor} btnBgColorHover={merchant?.buttonHoverBackgroundColor} btnBorderColor={merchant?.buttonBorderColor} btnTextColorHover={merchant?.buttonHoverTextColor} btnBorderColorHover={merchant?.buttonHoverBorderColor} fullwidth={true} onClick={() => form.submit()}>{screen ? screen.data.continueButton : ''}</Button>
            </div>
          </div>
        </>
      </InnerContainer>
    </Container>
  );
};

export default Receiverdata;