import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { MainTitle, Container, InnerContainer, Button, TextBox } from "app-premitives/index";
import { useDispatch, useSelector } from "react-redux";
import useMerchantInfo from "app-core/theme/theme.hook";
import { getOccassion, setFinalUpdateMode, setGiftdata } from "app-pages/merchant/merchant.slice";
import backButton from 'app-assets/images/caret-left-solid.svg'
import { selectFinalUpdateMode ,selectOccasionList, selectMerchant ,selectGiftData, selectConfigs, selectScreenInfo } from 'app-pages/merchant/merchant.selector'

const Message: React.FC = () => {
  const { id } = useParams();
  const giftData = useSelector(selectGiftData);
  const merchant = useSelector(selectMerchant)
  const config = useSelector(selectConfigs)
  const screenInfo = useSelector(selectScreenInfo);
  const [screen, setScreen] = useState(null) as any

  const navigate = useNavigate();
  const dispatch = useDispatch();
 // const bgImage = config.themeUrl+merchant.backgroundImage
  const bgImage = giftData.activeOccasion.occasionBg
  

  const finalMode = useSelector(selectFinalUpdateMode);

  useEffect(()=>{
    if (screenInfo && screenInfo.screenData) {
      setScreen(screenInfo.screenData.find((screens:any) => screens.screenId === 6))
    }
  },[screenInfo && screenInfo?.screenData])

  const handleContinue = () => {

    if(finalMode.text){
      navigate(`/${id}/preview`);
    }else{
      navigate(`/${id}/imageupload`);
    }
    
  };

  const handleSkip = () => {

    if(finalMode.text){
      navigate(`/${id}/preview`);
    }else{
      dispatch(setGiftdata({ ...giftData, activeOccasion: { ...giftData.activeOccasion, occasionDesc: formData.occassionDesc } }));
      navigate(`/${id}/imageupload`);
    }
    
  }

  const [formData] = useState({
    occassionDesc: giftData.activeOccasion.occasionDesc || ""
  });

  const handleTextChange = (newValue:any) => {
    // Update the redux state or local state with the new text value
    dispatch(setGiftdata({ ...giftData, activeOccasion: { ...giftData.activeOccasion, occasionDesc: newValue } }));
  };

  return (
    <Container>
      <InnerContainer header={
        <div className="flex items-center text-[12px] font-medium cursor-pointer" onClick={() => {
          navigate(`/${id}/occasion`)
        }}>
          <img src={backButton} className="h-[12px] w-[12px]" />
          Back
        </div>
      }>
        <>
          <div className="w-full">
            <MainTitle size="3xl" align="center">{screen ? screen.data.title : ''}</MainTitle>
            <div className="mt-6"></div>
          </div>

          <div id="message-box" className={`w-full mb-8 h-full rounded-xl bg-[url(${bgImage})] p-6 bg-cover`}>
          <TextBox value={giftData.activeOccasion.occasionDesc} onChange={handleTextChange} />
          </div>

          <div className='w-full'>
            <div className="flex flex-col justify-center gap-4">
              <Button type="normal" fullwidth={true} onClick={handleSkip}>
                <div className="flex flex-col">
                  {screen ? screen.data.generateButonText1 : ''}
                  <span className="text-[10px]">{screen ? screen.data.generateButonText2 : ''}</span>
                </div>
              </Button>
              <Button type='base' fullwidth={true} onClick={handleContinue}>
                {screen ? screen.data.continueButton : ''}
              </Button>
            </div>
          </div>
        </>
      </InnerContainer>
    </Container>
  );
}

export default Message;
