import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getMerchants, getScreenInfo, setThemee} from 'app-root/pages/merchant/merchant.slice';
import {selectGiftData, selectIsTokenPresent, selectMessage, selectToken,} from 'app-pages/merchant/merchant.selector'
import usePageTimeout from './page-timeout.hook';
import { useParams } from 'react-router-dom';

const useMerchantInfo = ({ merchantId }: any) => {

  const { id, token } = useParams();
  const selectMerchant = (state: any) => state.merchantSlice.merchant;
  const dispatch = useDispatch();
  const merchantList = useSelector(selectMerchant);
  const [ready, setReady] = useState<Boolean>(false);

  useEffect(() => {
    if(!ready){
      //localStorage.clear();
      setReady(true);
    }
  }, [ready]);

  useEffect(() => {
    if (merchantList && !merchantList.accessToken)
      dispatch(getMerchants({ merchantId }))
  }, [])

  useEffect(() => {
    if (ready && merchantList?.merchantId) {
      dispatch(getScreenInfo({ merchantId: merchantList?.merchantId }));
    }
  }, [ready, merchantList && merchantList.merchantId]);

  // useEffect(() => {
  //   localStorage.clear();
  //   dispatch(getMerchants({ merchantId }));
  // }, [merchantId, dispatch]);

  return { merchantList };
};

export default useMerchantInfo;