import React from "react";
import { TitleProps } from "app-premitives/premitives.type";

export const MainTitle = ({ children, align, size, className, weight, color }: TitleProps) => {
  return <h2 className={`md:text-${size ?? "3xl"} ${className} text-xl text-[${color}] text ${weight ?? "font-semibold"} leading-tight text-${align ?? "center"}`}>{children}</h2>;
};

export const Title = ({ children, align }: any) => {
  return <h2 className="text-[32px] font-semibold">{children}</h2>;
};

export const SystemTitle = ({ children }: any) => {
  return <h3 className="text-4 font-semibold text-[#566CED] py-2 px-4 bg-[#EFF1FE] rounded-[32px] mx-auto my-0">{children}</h3>;
};
