import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { MainTitle, Paragraph, Button, SystemTitle, List } from "app-root/components/premitives";
import pandaImage from "app-assets/images/Group 69.png"
import { selectMerchant } from "app-pages/merchant/merchant.selector";


const Mission = () => {

  const merchant = useSelector(selectMerchant);
  const siteName = merchant.siteName;
  const navigate = useNavigate();

  const onRecordClick = () => {
    localStorage.removeItem("recordData");
    if (merchant.requestToken) {
      navigate(`../${siteName}/giftcode`);
    } else {
      navigate(`../${siteName}/senderdata`);
    }
  };

  return (
    <div className="md:flex md:py-[64px] py-[60px] md:px-0 px-4 justify-between">
      <div className="flex flex-col items-center justify-center md:flex-row">
      <div>
        <img src={pandaImage} alt="Party Panda" />
      </div>
        <div className="flex flex-col md:w-1/2">
          <MainTitle align="left" weight="font-bold" size="5xl">Ready to try?</MainTitle>
          <div className="flex gap-5 mt-10">
            <Button type="base" onClick={()=>{navigate(`../${siteName}/play`);}}>Play message</Button>
            <Button type="outline" onClick={onRecordClick}>Start recording</Button>
          </div>
        </div>
        
      </div>
    </div>
  );
};

export default Mission;
