import copyToClipboard from 'clipboard-copy';
import { Helmet } from 'react-helmet-async';
import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Paragraph, Container, InnerContainer, Button, MainTitle, Info, InputField } from 'app-premitives/index';
import useMerchantInfo from 'app-core/theme/theme.hook';
import welcomeImage from 'app-root/assets/images/PartyPanda1.png';
import clipboardIcon from 'app-assets/images/clipboard.png'
import { selectMerchant, selectGiftData, selectCreateMessage, selectScreenInfo, selectDraftData, selectConfigs } from 'app-pages/merchant/merchant.selector'
import ribbon from 'app-assets/images/ribbon.png'
import usePageTimeout from 'app-root/core/theme/page-timeout.hook';

const Congrats: React.FC = () => {
  const { id } = useParams();
  const merchantList = useSelector(selectMerchant);
  const merchant = useSelector(selectMerchant);
  const giftData = useSelector(selectGiftData);
  const message = useSelector(selectCreateMessage);
  const [isCopied, setIsCopied] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const screenInfo = useSelector(selectScreenInfo);
  const [screen, setScreen] = useState(null) as any
  const draftData = useSelector(selectDraftData);
  const config = useSelector(selectConfigs);
  const navigate = useNavigate();
  const status = merchant?.typeId;
  const isRedirect = screenInfo?.screenData?.find((screens:any) => screens.screenId === 1)?.data?.redirectToMerchentSite;

  usePageTimeout();

  useEffect(()=>{
    if(!giftData.senderfname && !giftData.sendermail && !giftData.recieverfname && !giftData.activeOccasion && !message.id){
      navigate(`/${id}/`)
    }
  
},[])

  useEffect(() => {
    if (message) {
      setIsLoading(false);
      localStorage.clear();
    }
  }, [message])

  useEffect(() => {
    if (screenInfo && screenInfo.screenData) {
      setScreen(screenInfo.screenData.find((screens: any) => screens.screenId === 11))
    }
  }, [screenInfo && screenInfo?.screenData])


  const handleCopyCode = () => {
    copyToClipboard(message.token);
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 3000);
  };

  const whastappClick = () => {
    const whatsappURL = `whatsapp://send?text=Hi ${giftData.recieverfname}, ${giftData.senderfname} sent you a Voicey Pix 🎤 💌. %0aPlay it here: ${message.qrData}`;
    window.location.href = whatsappURL;
  };

  const emailClick = () => {
    const subject = `${giftData.activeOccasion.occasionName} from ${giftData.senderfname}`;
    const body = `Hi ${giftData.recieverfname}, ${giftData.senderfname} sent you a Voicey Pix 🎤 💌. Play it here: ${message.qrData}`;

    let mailtoURL;

    if (giftData.recievermail) {
      mailtoURL = `mailto:${giftData.recievermail}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
    } else {
      mailtoURL = `mailto:?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
    }

    window.location.href = mailtoURL;
  };

  const shareOnFacebook = () => {
    // Get the image URL, share URL, and caption
    const imageUrl = giftData.activeOccasion.occasionBg;
    const shareUrl = message.qrData;
    const shareCaption = 'Your caption goes here';

    // Construct the Facebook share URL with the image, link, and caption
    const facebookShareUrl = `https://www.facebook.com/dialog/share?app_id=3553960768186676&display=popup&href=${encodeURIComponent(shareUrl)}&quote=${encodeURIComponent(shareCaption)}&picture=${encodeURIComponent(imageUrl)}`;

    // Open the Facebook share dialog in a new window
    window.open(facebookShareUrl, '_blank');
  };


  return (
    <>

      <Container>
        <InnerContainer merchantLogo={merchant?.logo} hrefLink={isRedirect ? `../${id}` : '../'} bgColor={merchant?.backgroundColor} header={
          <div className='mt-[18px]'></div>
        }>
          <>
            {isLoading ? (
              <div className='flex items-center w-full h-full'>
                <div v-if="loading" className="spinner">
                  <div className="rect1"></div>
                  <div className="rect2"></div>
                  <div className="rect3"></div>
                  <div className="rect4"></div>
                  <div className="rect5"></div>
                </div>
              </div>
            ) : (
              <>
                <div className='flex flex-col justify-between'>
                  <div className='flex flex-col justify-between'>
                    <MainTitle align='center' className='text-purple-500' size='3xl'>{screen ? screen?.data?.title : ''}</MainTitle>
                    {status === 2 && (
                      <div className="mt-4 text-center">
                        <Paragraph align="center">
                          {/* {screen ? screen.data.description : ''} */}
                          {screen?.data?.description.replace("{0}!", giftData?.senderfname).split(giftData?.senderfname).map((part: any, index: any) => (
                            <span key={index}>
                              {index > 0 && (
                                <span style={{ color: `${merchant?.textboxFocusColor ?? '#566CED'}`, fontWeight: '600' }}>
                                  {giftData?.senderfname}!
                                </span>
                              )}
                              <div dangerouslySetInnerHTML={{__html: part}} />
                            </span>
                          ))}
                        </Paragraph>
                      </div>
                    )}
                  </div>

                </div>
                {status === 2 && (
                  <div className='flex items-center justify-center'>
                  {config.merchentUrl &&  screen.data.image &&(
                  <img className='object-cover w-full h-[250px]' src={config.merchentUrl + screen.data.image} />
                )}
                </div>
                )}

                <div className='flex flex-col justify-between'>
                  <div className='flex justify-center mt-8'>
                    {!draftData.result && (
                      <Paragraph weight='font-semibold'>{screen ? screen.data.shareDescription : ''}</Paragraph>
                    )}
                  </div>
                  <div className="flex justify-center gap-4 mt-4">
                    {!draftData.result && (
                      <>
                        <div className="social-container">
                          <a onClick={whastappClick}><i className="fab fa-whatsapp"></i></a>
                          <a onClick={shareOnFacebook}><i className="fab fa-facebook-f"></i></a>
                          <a onClick={emailClick}><i className="fa-regular fa-envelope"></i></a>
                        </div>
                      </>
                    )}
                  </div>
                </div>

                {/* <div className='absolute flex items-center justify-center top-1/2 translate-y-[-50%]'>
                <img id='welcomeImg' className='object-cover w-full h-[200px]' src={ribbon} />
              </div> */}

                <div className='w-full'>
                  <div className="flex flex-col justify-center gap-4 text-center">
                    <Paragraph align='center' weight='font-semibold'>{screen?.data?.onlineMessage}</Paragraph>
                    <Button btnTextColor={merchant?.buttonTextColor} btnBgColor={merchant?.buttonBackgroundColor} btnBgColorHover={merchant?.buttonHoverBackgroundColor} btnBorderColor={merchant?.buttonBorderColor} btnTextColorHover={merchant?.buttonHoverTextColor} btnBorderColorHover={merchant?.buttonHoverBorderColor} type='base' fullwidth={true} onClick={() => { window.location.href = '../' }}>{screen?.data?.sendAnother}</Button>
                  </div>
                </div>
              </>
            )}
          </>
        </InnerContainer>
      </Container>
    </>
  );
};

export default Congrats;
