import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Paragraph, Container, InnerContainer, Button, MainTitle } from 'app-premitives/index';
import useMerchantInfo from 'app-core/theme/theme.hook';
import welcomeImage from 'app-root/assets/images/PartyPanda1.png'
import {selectMerchant, inValidMerchant, selectToken, selectMessage, selectDraftData, selectReady, selectConfigs} from 'app-pages/merchant/merchant.selector';
import { getConfig, getOccassion, getTokenStatus, getPlayMessage, setGiftdata, getMerchants, getScreenInfo, getMessage, setIsTokenPresent } from 'app-pages/merchant/merchant.slice';
import PlayMessage from '../playmessage/playmessage';
import Welcome from './welcome';

const TokenVerification: React.FC = (props) => {

  const { id, token } = useParams();
  const theme = useMerchantInfo({merchantId: id});
  const merchant = useSelector(selectMerchant);
  const isInvalidMerchant = useSelector(inValidMerchant);
  const giftToken = useSelector(selectToken);
  const playMessage = useSelector(selectMessage)
  const draftData = useSelector(selectDraftData)
  const merchantId = merchant?.merchantId
  const ready = useSelector(selectReady);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(setIsTokenPresent(!!token));
  }, [token]);
  // useEffect(() => {
  //   if(!ready){
  //     localStorage.clear();
  //     setReady(true);
  //   }
  // }, [ready]);

  useEffect(()=>{
    if(theme===undefined && ready)
    dispatch(getMerchants({ theme }))
  },[theme, ready])

  useEffect(() => {
      dispatch(getOccassion());
  }, []);


  useEffect(() => {
    dispatch(getConfig());
  }, []);

  useEffect(() => {
    if(ready && merchant?.merchantId){
      dispatch(getScreenInfo({ merchantId: merchant?.merchantId }))
    }
  }, [ready]);

   useEffect(()=>{
    
    if(giftToken?.message == "New"){
        navigate(`/${id}/`)
    }
    else if(giftToken?.message == "Draft" && draftData?.result?.sender && draftData?.result?.receiver){
      dispatch(setGiftdata({ giftCode: token, senderfname: draftData.result.sender.firstName, 
        senderlname: draftData.result.sender.lastName, sendermail: draftData.result.sender.email, 
        recieverfname: draftData.result.receiver.firstName, recieverlname: draftData.result.receiver.lastName, recievermail: draftData.result.receiver.email}));  
      
      navigate(`/${id}/`)
    }
    
  },[giftToken, draftData && draftData?.result, token])


  // useEffect(() => {
  //   if (isInvalidMerchant) {
  //     navigate('/aboutus');
  //   }
  // }, [isInvalidMerchant, navigate]);
  

  useEffect(()=>{
    if(ready && token){
      dispatch(getTokenStatus({merchantId: merchant?.merchantId, giftCode: token, action: 0}));
    }
  },[ready, token])


  useEffect(()=>{

if(giftToken.status_code == 200 && ready){
  if(giftToken?.result?.status == 1 || giftToken?.result?.status == 2){
    dispatch(getPlayMessage({ merchantId: merchant?.merchantId, giftCode: token }));
  }
  else if(giftToken?.result?.status == 0){
    dispatch(setGiftdata({ giftCode: token}));
    dispatch(getTokenStatus({ merchantId: merchant?.merchantId, giftCode: token, action: 1}));
  }
  else if(giftToken?.result?.status == 3){
    dispatch(getMessage({ merchantId: merchant?.merchantId, giftCode: token }));
  }

}else if(giftToken.status_code == -99 && ready){
  navigate(`/${id}/`);
}
  },[giftToken, ready, merchant && merchant?.merchantId, token])

  if(giftToken?.result?.status == 1 || giftToken?.result?.status == 2 && playMessage?.statusCode === 200){
    // navigate(`/${id}/play/giftmessage`);
    return (
      <Welcome></Welcome>
    );
  }else{
    return (
      <Container>
        <InnerContainer merchantLogo={merchant?.logo} header={
          <div className='mt-[18px]'></div>
        }>
              <div className='flex items-center w-full h-full'>
                <div v-if="loading" className="spinner">
                  <div className="rect1"></div>
                  <div className="rect2"></div>
                  <div className="rect3"></div>
                  <div className="rect4"></div>
                  <div className="rect5"></div>
                </div>
              </div>
            
        </InnerContainer>
      </Container>
    );
  }
 
};

export default TokenVerification;