export const apiUrl = () => {

    const dev = {
        getAllOccassions: '/Occasion/GetOccasions',
        getMerchantByName: merchant => `/Merchant/GetMerchantInfo/${merchant}`,
        getTokenByGiftcode: token => `/Token/GetTokenStatus?token=${token}`,
        getConfigData: `/Config/GetConfigData`,
        getImageUrlByUploadedImage: `/Message/SaveImage`,
        getAudioUrlByRecording: `/Message/SaveAudio`,
        getCreateMessage: `/Message/CreateMessage`,
        getPlayMessage: (merchantid, token) => `/Message/PlayMessage?merchantId=${merchantid}&token=${token}`,
        getTokenStatus: (merchantid, token, action) => `/Token/GetTokenStatus?merchantId=${merchantid}&token=${token}&action=${action}`,
        getPaymentStatus: merchantid => `/Payment/GetPaymentLink?merchantId=${merchantid}`,
        getDefaultMerchant: `/Merchant/GetDefaultMerchantInfo`,
        getScreenInfo: merchantid => `/Screen/GetScreenInfo?merchantId=${merchantid}`,
        getMessage: (merchantid, token) => `/Message/GetMessage?merchantId=${merchantid}&token=${token}`,
    }

    const Prod = {
        getAllOccassions: '/Occasion/GetOccasions',
        getMerchantByName: merchant => `/Merchant/GetMerchantInfo/${merchant}`,
        getTokenByGiftcode: token => `/Token/GetTokenStatus?token=${token}`,
        getConfigData: `/Config/GetConfigData/`,
        getImageUrlByUploadedImage: `/Message/SaveImage`,
        getAudioUrlByRecording: `/Message/SaveAudio`,
        getCreateMessage: `/Message/CreateMessage`,
        getPlayMessage: (merchantid, token) => `/Message/PlayMessage?merchantId=${merchantid}&token=${token}`,
        getTokenStatus: (merchantid, token, action) => `/Token/GetTokenStatus?merchantId=${merchantid}&token=${token}&action=${action}`,
        getPaymentStatus: merchantid => `/Payment/GetPaymentLink?merchantId=${merchantid}`,
        getDefaultMerchant: `/Merchant/GetDefaultMerchantInfo`,
        getScreenInfo: merchantid => `/Screen/GetScreenInfo?merchantId=${merchantid}`,
        getMessage: (merchantid, token) => `/Message/GetMessage?merchantId=${merchantid}&token=${token}`,
    }

    if (process.env.NODE_ENV === 'development')
        return dev
    else
        return Prod
}