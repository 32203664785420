import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Paragraph, Container, InnerContainer, Button, MainTitle } from 'app-premitives/index';
import useMerchantInfo from 'app-core/theme/theme.hook';
import { selectMerchant, selectScreenInfo } from 'app-pages/merchant/merchant.selector'


const Payment: React.FC = () => {
  const { id } = useParams();
  const merchantList = useSelector(selectMerchant);
  const screenInfo = useSelector(selectScreenInfo);
  const [screen, setScreen] = useState(null) as any


  useEffect(() => {
    if (screenInfo && screenInfo.screenData) {
      setScreen(screenInfo.screenData.find((screens: any) => screens.screenId === 10))
    }
  }, [screenInfo && screenInfo?.screenData])


  return (
    <Container>
      <InnerContainer>
        <>
          <div>
            <MainTitle align='center' size='3xl'>{screen ? screen.data.title : ''}</MainTitle>
          </div>

          <div className='w-full'>
            <div className="flex flex-col justify-center">
              <Button type='base' fullwidth={true} linkUrl={`/${id}/congrats`}>
                {screen ? screen.data.continueButton : ''}
              </Button>
            </div>
          </div>
        </>
      </InnerContainer>
    </Container>
  );
};

export default Payment;