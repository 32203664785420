import React, { useEffect, useState, useCallback } from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { useDropzone } from 'react-dropzone';
import { useDispatch, useSelector } from "react-redux";
import { MainTitle, Container, InnerContainer, Button, ThemeHeader, Paragraph, Info } from "app-premitives/index";
import useMerchantInfo from "app-core/theme/theme.hook";
import { getImageUrlByUploadedImage, getOccassion, getScreenInfo, setGiftdata, setImageUrlByUploadedImage } from "app-pages/merchant/merchant.slice";
import UploadImageIcon from 'app-root/assets/images/camera.png'
import { selectGiftData, selectMerchant, selectConfigs, selectFinalUpdateMode, selectScreenInfo } from 'app-root/pages/merchant/merchant.selector'
import backButton from 'app-assets/images/caret-left-solid.svg'
import closeButton from 'app-assets/images/close-icon.png'
import Cropper from 'react-easy-crop'
import { Slider } from "antd";
import getCroppedImg from './cropImage';
import sayCheese from 'app-assets/images/hedgehog-photography-say-cheese.jpg';
import usePageTimeout from "app-root/core/theme/page-timeout.hook";

const ImageUpload: React.FC = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [uploadedImage, setUploadedImage] = useState<File | null>(null);
  const [crop, setCrop] = useState({ x: 0, y: 0, width: 1, height: 1 } as any)
  const [zoom, setZoom] = useState(1);
  const [imageUrl, setImageUrl] = useState<string | undefined>();
  const [base64String, setBase64String] = useState();
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
  const screenInfo = useSelector(selectScreenInfo);
  const [screen, setScreen] = useState(null) as any

  const giftData = useSelector(selectGiftData);
  const merchant = useSelector(selectMerchant)
  const config = useSelector(selectConfigs)
  const isRedirect = screenInfo?.screenData?.find((screens:any) => screens.screenId === 1)?.data?.redirectToMerchentSite;

 // const bgImage = config.themeUrl + merchant.backgroundImage
  const bgImage = giftData?.activeOccasion?.occasionColor
  const isRecordVoice = giftData?.activeOccasion?.voiceRecord
  const finalUpdateMode = useSelector(selectFinalUpdateMode);

  useEffect(()=>{
    if(!giftData.senderfname && !giftData.sendermail && !giftData.recieverfname && !giftData.activeOccasion){
      navigate(`/${id}/`)
    }
  
},[])

  const onDrop = (acceptedFiles: File[]) => {
    if (acceptedFiles.length > 0) {
      setUploadedImage(acceptedFiles[0]);
      setImageUrl(URL.createObjectURL(acceptedFiles[0]));

      const file = acceptedFiles[0]

      var reader = new FileReader();
      reader.onload = function (event: any) {
        var base64String = event.target.result;
        var fileName = file.name
        setBase64String(base64String)
      };
      reader.readAsDataURL(file);
    }
  };


  const getCroppedImage = useCallback(async () => {
    try {
      const image = await getCroppedImg(
        base64String,
        croppedAreaPixels
      )
      dispatch(setGiftdata({ imageString: image.base64 }));
      
      return await getCroppedImg(
        base64String,
        croppedAreaPixels
      )
    } catch (e) {
      console.log("error on image crop: ", e);
      
    }
  }, [croppedAreaPixels])

  usePageTimeout();

  useEffect(() => {
    console.log('screen info', screenInfo.screenData)
    if (merchant && screenInfo.screenData === undefined) {
      dispatch(getScreenInfo({merchantId: merchant?.merchantId}))
    }
  }, [merchant]);

  useEffect(()=>{
    if (screenInfo && screenInfo.screenData) {
      setScreen(screenInfo.screenData.find((screens:any) => screens.screenId === 7))
    }
  },[screenInfo && screenInfo?.screenData])


  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: { 'image/*': [] },
    multiple: false,
  });

  const onCropChange = useCallback((crop:any) => {
    setCrop(crop);
  }, []);
  
  const onContinueClick = () => {
    if (finalUpdateMode.image) {
      if (uploadedImage) {
        // Crop the image before navigating to the next step
        getCroppedImage();
  
        navigate(`/${id}/preview`);
      } else {
        navigate(`/${id}/preview`);
      }
    } else {
      if (uploadedImage) {
        // Crop the image before navigating to the next step
        getCroppedImage();
  
        if(isRecordVoice==true){
          navigate(`/${id}/recordvoice`);
        }
        else{
          navigate(`/${id}/preview`);
        }
      } else {
        if(isRecordVoice==true){
          navigate(`/${id}/recordvoice`);
        }
        else{
          navigate(`/${id}/preview`);
        }
      }
    }
  };
  

  const onSkipClick = () => {
    navigate(`/${id}/recordvoice`);
  }

  const onRemoveClick = () => {
    setUploadedImage(null);
  };

  const onCropComplete = useCallback((croppedArea: any, croppedAreaPixels: any) => {
    setCroppedAreaPixels(croppedAreaPixels)
  }, [])

  return (
    <Container>
      <InnerContainer merchantLogo={merchant?.logo} hrefLink={isRedirect ? `../${id}` : '../'} bgColor={merchant?.backgroundColor} header={
        <div className="flex items-center text-[12px] font-medium cursor-pointer" onClick={() => {
          if(isRecordVoice==true){
            navigate(`/${id}/occasion`)
          }
          else{
            navigate(`/${id}/message`)
          }
          
        }}>
          <img src={backButton} className="h-[12px] w-[12px]" />
          Back
        </div>
      }>
        <>
          <div className="">
            <MainTitle size="3xl" align="center">{screen ? screen.data.title : ''}</MainTitle>
            <div className="">
              <div className="text-center md:mt-6">
                <Paragraph align='center'>
                  <div dangerouslySetInnerHTML={{__html: screen?.data?.description}} />
                </Paragraph>
              </div>
            </div>
          </div>

          <div className={`w-full h-[340px] md:h-[400px] flex flex-col justify-center items- bg-no-repeat bg-cover p-6 rounded-2xl`}>
            {/* <div className={`w-full h-full cursor-pointer flex flex-col justify-center items-center bg-no-repeat bg-cover rounded-2xl `}> */}

            <div className={`relative w-full h-full cursor-pointer flex flex-col justify-center items-center bg-no-repeat bg-cover rounded-2xl ${!uploadedImage ? `bg-[url(${giftData.imageString ? giftData.imageString : sayCheese})]` : ''}  `} >
              {/* Dropzone */}
              <div
                {...uploadedImage ? '' : { ...getRootProps({ className: 'w-full h-full dropzone flex flex-col items-center justify-center' }) }}

              >
                <input {...getInputProps()} />
                {uploadedImage ? (
                  <>
                    <div className="w-full h-full ">
                      <button className="absolute text-white cursor-pointer top-2 right-2 z-[999]" onMouseUp={onRemoveClick}><img src={closeButton} /></button>
                      <Cropper
                        image={imageUrl}
                        crop={crop}
                        zoom={zoom}
                        onCropChange={onCropChange}
                        onCropComplete={onCropComplete}
                        onZoomChange={setZoom}
                        aspect={1 / 1}
                        objectFit="cover"

                      />
                      {/* <img className="object-cover w-full h-full rounded-2xl" src={URL.createObjectURL(uploadedImage)} alt="Uploaded Image" /> */}
                    </div>
                  </>
                ) : (
                  <>
                    <div className="rounded-full bg-white p-[24px] mb-5">
                      <img src={UploadImageIcon} alt="Upload Your Image" />
                    </div>
                    <Info color="white">Capture your image here or browse(JPG or PNG)</Info>
                  </>
                )}

              </div>
            </div>
            {uploadedImage ? (
              <div className="flex items-center gap-4 px-4 mt-4 bg-white rounded-lg">
                <i className="fa fa-minus" aria-hidden="true" onClick={()=>{
                  if(zoom>1){
                    setZoom(zoom-0.1)
                  }
                  }}></i>
                <Slider className="w-full" tooltip={{ formatter: null }} min={1} max={3} step={0.1} value={zoom} onChange={(value: number) => setZoom(value)} />
                <i className="fa fa-plus" aria-hidden="true" onClick={()=>{
                  if(zoom<3){
                  setZoom(zoom+0.1)
                  }
                  }}></i>
              </div>
            ) : ("")}
          </div>
          {/* <button onClick={camON}>open cam</button>
          <video id="vid" height="120" width="160" autoPlay></video> */}
          <div className='w-full'>
            <div className="flex flex-col justify-center gap-4">
              <Button type="normal" btnBgColorHover={merchant?.buttonBackgroundColor} btnTextColor={merchant?.buttonTextColor} btnTextColorHover={merchant?.buttonHoverTextColor} btnBorderColorHover={merchant?.buttonBackgroundColor} fullwidth={true} onClick={onSkipClick} >
                <div className="flex flex-col">
                  {screen ? screen.data.generateButonText1 : ''}
                  <span className="text-[10px]">{screen ? screen.data.generateButonText2 : ''}</span>
                </div>
              </Button>
              <Button type='base' btnTextColor={merchant?.buttonTextColor} btnBgColor={merchant?.buttonBackgroundColor} btnBgColorHover={merchant?.buttonHoverBackgroundColor} btnBorderColor={merchant?.buttonBorderColor} btnTextColorHover={merchant?.buttonHoverTextColor} btnBorderColorHover={merchant?.buttonHoverBorderColor} fullwidth={true} onClick={onContinueClick} >{screen ? screen.data.continueButton : ''}</Button>
            </div>
          </div>
        </>
      </InnerContainer>
    </Container>
  );
};

export default ImageUpload;