import React from 'react';
import { Title, Paragraph, Container, LinkButton, Info, InnerContainer } from 'app-premitives/index';
import { Input } from 'antd';

const Recordmessage: React.FC = () => {
  return (
    <Container>
      <InnerContainer>
        <>
        <div>
          <Title>Record your message</Title>
          <div className="mt-4">
            <Paragraph>
              Lorem ipsum dolor sit amet consectetur. Auctor sed consequat sed varius id massa aliquam pretium. Commodo vel est consequat sit commodo suspendisse elementum amet.
            </Paragraph>
          </div>
          <div className="flex justify-center mt-4">
            <Input placeholder="Enter your gift code" />
          </div>
        </div>

        <div>
          <div className="flex justify-center mt-14">
            <LinkButton link="userdata">Continue</LinkButton>
          </div>
          <div className="flex justify-center mt-4">
            <Info>We will charge you €0.99 for sending it.</Info>
          </div>
        </div>
        </>
      </InnerContainer>
    </Container>
  );
};

export default Recordmessage;
