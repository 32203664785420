import { MainTitle, Paragraph, Button, SystemTitle, List } from "app-root/components/premitives";
import React from "react";

const Mission = () => {
  return (
    <div className="md:flex md:py-[64px] py-[60px] md:px-0 px-4 justify-between">
      <div className="flex flex-col justify-center gap-16">
        <div className="flex flex-col justify-center gap-6 m-auto text-center md:w-1/2">
          <SystemTitle>Mission</SystemTitle>
          <MainTitle size="5xl" weight="font-bold">Our Mission</MainTitle>
          <Paragraph size="lg" align="center" weight="font-semibold">At Recqord, our mission is to enrich the way people connect and celebrate special moments. 
            We strive to provide a platform where your messages are not just words but a heartfelt experience, 
            conveyed through the warmth of your voice.
            </Paragraph>
        </div>
      </div>
    </div>
  );
};

export default Mission;
