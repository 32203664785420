import React from "react";
import Header from "app-components/header";
import { Button, LinkButton, List, SystemTitle, InputField, Title, MainTitle } from "app-premitives/index";
import { ListItemProps } from "app-premitives/premitives.type";

const listItems: ListItemProps[] = [
  { id: 1, text: "Item 1", isChecked: true },
  { id: 2, text: "Item 2", isChecked: true },
  { id: 3, text: "Item 3", isChecked: true },
];

const Design = () => {
  return (
    <div className="">
      <div>
        <Header />
        <h2>Buttons</h2>
        <div className="flex gap-[30px]">
          <Button type="base">Base</Button>
          <Button type="outline">Outline</Button>
          <Button type="line">Line</Button>
        </div>
        <h2 className="mt-10">List</h2>
        <List items={listItems} />

        <div className="flex items-center gap-10">
          <MainTitle>Main Title</MainTitle>
          <Title>Normal Title</Title>
          <SystemTitle>System Title</SystemTitle>
        </div>

        <h2 className="mt-10">Input</h2>
        <InputField hint="XXXX" />

      </div>
    </div>
  );
};

export default Design;
