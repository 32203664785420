import React, { useEffect, useState } from "react";
import { navigation } from "./utils";
import { useNavigate } from "react-router-dom";
import { LinkButton, Button } from "../premitives";
import { useDispatch, useSelector } from "react-redux";
import { selectMerchant } from "app-pages/merchant/merchant.selector";
import { getOccassion, getConfig, getDefaultMerchant, getScreenInfo } from "app-root/pages/merchant/merchant.slice";

const Header = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const logoUrl = process.env.REACT_APP_LOGO_URL;
  const merchant = useSelector(selectMerchant);
  const siteName = merchant.siteName;
  const merchantId = merchant.merchantId

  const navigate = useNavigate();

  const dispatch = useDispatch();

  

  useEffect(() => {
    dispatch(getOccassion());
    dispatch(getConfig());
    dispatch(getDefaultMerchant());
  }, []);

  useEffect(() => {
    if (merchantId) {
      dispatch(getScreenInfo({ merchantId }))
    }
  }, [merchantId]);

  const onPlayClick = () => {
    navigate(`../${siteName}/play`);
  };

  const onRecordClick = () => {
    localStorage.removeItem("recordData");
    if (merchant.requestToken) {
      navigate(`../${siteName}/giftcode`);
    } else {
      navigate(`../${siteName}/senderdata`);
    }
  };

  const onMenuClick = (menuItem: any) => {
    if (menuItem.url === "recording") {
      onRecordClick();
    } else {
      navigate(`/${menuItem.url}`);
    }
    closeMobileMenu();
  };

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen((prev) => !prev);
  };

  const closeMobileMenu = () => {
    setIsMobileMenuOpen(false);
  };

  return (
    <>
    {isMobileMenuOpen && (
          <div className="absolute w-full h-full z-[999] bg-white md:hidden px-4 py-6 text-[#566CED]">
            <div className="absolute right-0 px-4 text-3xl" onClick={closeMobileMenu}>
            <i className="fa-solid fa-xmark"></i>
            </div>
            <div className="flex flex-col gap-10">
            {Object.keys(navigation).map((key) => (
              <div key={key} className="flex gap-20 text-xl font-semibold" onClick={() => onMenuClick(navigation[key])}>
                {navigation[key].text}
              </div>
            ))}
            <div className="flex">
              <Button type="base" onClick={onPlayClick}>
                Play message
              </Button>
            </div>
            </div>
          </div>
        )}
    <div className="flex flex-col items-end justify-between px-4 my-6 text-lg font-semibold md:flex-row md:items-center md:px-0">
      <div className="w-full flex items-center justify-between text-[#566CED] cursor-pointer">
        <div>
        <img className="h-[46px]" src={logoUrl} onClick={()=>{navigate(`../`)}} alt="Voicey Pix Logo" />
        </div>
        <div className="text-3xl md:hidden" onClick={toggleMobileMenu}>
          <i className="fa-solid fa-bars"></i>
        </div>
        <div className="items-center hidden gap-20 md:flex">
          {Object.keys(navigation).map((key) => (
            <div key={key} className="flex gap-20" onClick={() => onMenuClick(navigation[key])}>
              {navigation[key].text}
            </div>
          ))}
        </div>
      </div>
      <div className="hidden mt-5 md:flex md:ml-8 md:mt-0">
        <Button type="base" onClick={onPlayClick}>
          Play message
        </Button>
      </div>
    </div>
    </>
  );
};

export default Header;
