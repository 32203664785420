import React, { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import Welcome from "./welcome/welcome";
import TokenVerification from "./welcome/tokenverification";
import Giftcode from "./giftcode/giftcode";
import Senderdata from "./senderdata/senderdata";
import Receiverdata from "./recieverdata/recieverdata";
import Recordmessage from "./recordmessage/recordmessage";
import Giftfor from "./giftfor/giftfor";
import Occasion from "./occasion/occasion";
import AudioVideo from "./audiovideo/audiovideo";
import ImageUpload from "./imageupload/imageupload";
import RecordVoice from "./recordvoice/recordvoice";
import Preview from "./preview/preview";
import Payment from "./payment/payment";
import Congrats from "./congrats/congrats";
import Cart from "./cart/cart";
import Message from "./message/message";
import PlayGiftcode from "./playgiftcode/playgiftcode"
import PlayMessage from "./playmessage/playmessage"


const Merchant = () => {

  return (
    <Routes>
          
          <Route path="/" element={<Welcome />} />
          <Route path="/:token" element={<TokenVerification />} />
          <Route path="/giftcode" element={<Giftcode />} />
          <Route path="/senderdata" element={<Senderdata />} />
          <Route path="/recieverdata" element={<Receiverdata />} />
          <Route path="/recordmessage" element={<Recordmessage />} />
          <Route path="/giftfor" element={<Giftfor />} />
          <Route path="/occasion" element={<Occasion />} />
          <Route path="/audiovideo" element={<AudioVideo /> } />
          <Route path="/imageupload" element={<ImageUpload /> } />
          <Route path="/recordvoice" element={<RecordVoice /> } />
          <Route path="/preview" element={<Preview /> } />
          <Route path="/payment" element={<Payment /> } />
          <Route path="/congrats" element={<Congrats />} />
          <Route path="/cart" element={<Cart />} />
          <Route path="/message" element={<Message />} />
          <Route path="/play" element={<PlayGiftcode />} />
          <Route path="/play/giftmessage" element={<PlayMessage />} />
        </Routes>
  );
};

export default Merchant;
