import { theme } from 'antd';
import { getRecordData, getDraftData, getOccassionsData, getMerchantData, getConfigData, getAudioData, getCreateMessageData, getPlayMessageData, getScreenInfoData } from './utils/useRecordData';

export const selectMerchant = (state: any) => Object.keys(state.merchantSlice.merchant).length === 0  ? getMerchantData() : state.merchantSlice.merchant;
export const inValidMerchant = (state: any) => state.merchantSlice.invalidMerchant;
export const selectToken = (state: any) => state.merchantSlice.token;
export const selectReady = (state: any) => state.merchantSlice.ready;
export const invalidToken = (state: any) => state.merchantSlice.invalidToken;
export const selectConfigs = (state: any) => Object.keys(state.merchantSlice.configs).length === 0  ? getConfigData() : state.merchantSlice.configs;
export const selectOccasionList = (state: any) => state.merchantSlice.occassions ?? getOccassionsData();
export const selectGiftData = (state: any) => Object.keys(state.merchantSlice.giftdata).length === 0  ? getRecordData() : state.merchantSlice.giftdata;
export const selectImage = (state: any) => state.merchantSlice.saveImage;
export const selectRecording = (state: any) => Object.keys(state.merchantSlice.saveRecording).length === 0  ? getAudioData() : state.merchantSlice.saveRecording;
export const selectMessage = (state: any) => Object.keys(state.merchantSlice.message).length === 0  ? getPlayMessageData() : state?.merchantSlice?.message;
export const selectFinalUpdateMode = (state: any) => state.merchantSlice.finalUpdateMode;
export const selectCreateMessage = (state: any) => Object.keys(state.merchantSlice.createMessage).length === 0  ? getCreateMessageData() : state.merchantSlice.createMessage;
export const selectPaymentStatus = (state: any) => state.merchantSlice.paymentStatus;
export const selectTheme = (state: any) => state.merchantSlice.theme.theme ? state.merchantSlice.theme.theme : localStorage.getItem("theme");
export const selectScreenInfo = (state: any) => Object.keys(state.merchantSlice.screenInfo).length !== 0  ? getScreenInfoData() : state.merchantSlice.screenInfo;
export const selectDraftData = (state: any) => Object.keys(state.merchantSlice.draftMessage).length === 0  ? getDraftData() : state.merchantSlice.draftMessage;
export const selectIsTokenPresent = (state: any) => state.merchantSlice.isTokenPresent;