import React from "react";
import Header from "app-components/header";
import Welcome from "app-pages/aboutus/welcome";
import Mission from "app-pages/aboutus/mission";
import WhyRecqord from "app-pages/aboutus/whyrecqord";
import ReadyToTry from "app-pages/aboutus/readytotry"
import Footer from 'app-components/footer'

const AboutUs = () => {
  return (
    <>
    <div className="xl:w-[1280px] w-full">
      <Header />
      <Welcome />
      <Mission />
      <WhyRecqord />
      <ReadyToTry />
    </div>
    <Footer />
    </>
  );
};

export default AboutUs;
