import React, { useCallback } from 'react';

const OccassionSlider = ({ occasion }: any) => {
  const occasionSrc = occasion?.imageUrl

  return (
    <>
      {occasion && (
        <div key={occasion.id}>
          <div className='flex items-center justify-center w-full'>
            <div className={`flex flex-col justify-center items-center w-full py-5 px-5 rounded-[32px] p-6 mx-1 slideritem bg-[${occasion.colorCode}]`}>
              <div className="flex flex-col justify-center gap-4">
                {/* <img src={occasionSrc} className="object-cover w-auto h-auto" />
                <div className="bg-white h-[100px] rounded-[16px]">
                  <p className="m-4 outline-none focus:border-none">
                    {occasion.message}
                  </p>
                </div> */}
                <img className='w-[250px] h-[380px] rounded-2xl' src={occasionSrc} alt="occasion" />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default OccassionSlider;
