import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Paragraph, Container, InnerContainer, Button, MainTitle } from 'app-premitives/index';
import useMerchantInfo from 'app-core/theme/theme.hook';
import { selectMerchant, inValidMerchant, selectToken, selectScreenInfo, selectConfigs, selectReady, selectMessage, selectIsTokenPresent } from 'app-pages/merchant/merchant.selector';
import { getConfig, getOccassion, getMerchants, getScreenInfo, getTokenStatus, setGiftdata } from 'app-pages/merchant/merchant.slice';
import usePageTimeout from 'app-root/core/theme/page-timeout.hook';
import PlayMessage from '../playmessage/playmessage';

const Welcome: React.FC = (props) => {
  const { id, token } = useParams();
  const theme = useMerchantInfo({ merchantId: id });
  const merchantData = useSelector(selectMerchant);
  const isTokenPresent = useSelector(selectIsTokenPresent);
  const configData = useSelector(selectConfigs);
  const [merchant, setMerchant]=useState([]) as any;
  const [config, setConfig]=useState([]) as any;
  const isInvalidMerchant = useSelector(inValidMerchant);
  const screenInfo = useSelector(selectScreenInfo);
  const [screen, setScreen] = useState(null) as any;
  const ready = useSelector(selectReady);
  const giftToken = useSelector(selectToken);
  const [hidePlayButton, setHidePlayButton] = useState(false);
  const [showInvalidMessage, setShowInvalidMessage] = useState(false);
  const [showBothButtons, setShowBothButtons] = useState(false);
  const [hideRecordButton, setHideRecordButton] = useState(false);
  const recordData = localStorage.getItem('recordData') as any;
  const messageData = localStorage.getItem('message') as any;
  const playMessage = JSON.parse(messageData);
  const storedToken = JSON.parse(recordData);
  const isRedirect = screenInfo?.screenData?.find((screens:any) => screens.screenId === 1)?.data?.redirectToMerchentSite;
  const merchantTypeId = merchant?.typeId;
  
  const message = useSelector(selectMessage);
  // merchantTYpeId == 1 is offline and 2 is online
  const dispatch = useDispatch();
  const navigate = useNavigate();

  usePageTimeout();

  useEffect(() => {
    if (ready && merchant?.merchantId) {
      dispatch(getScreenInfo({ merchantId: merchant?.merchantId }));
    }
  }, [ready, merchant && merchant.merchantId]);

  useEffect(()=>{
    const existingMerchant = localStorage.getItem('MerchantInfo') as any;
    const existingMerchantParse = JSON.parse(existingMerchant);
    const existingScreenInfo = localStorage.getItem('ScreenInfo') as any;
    const siteName: any = existingMerchantParse?.siteName;
    if(siteName && siteName === id?.toLocaleLowerCase()){
      setMerchant(merchantData);
      setConfig(configData);

      if(existingScreenInfo){
        setScreen(screenInfo);
      }
    }
    

  },[JSON.stringify(merchantData), JSON.stringify(configData), JSON.stringify(screenInfo)])

  useEffect(() => {
    if (theme === undefined && ready) {
      dispatch(getMerchants({ theme }));
    }
  }, [theme, ready, dispatch]);

  useEffect(() => {
    dispatch(getOccassion());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getConfig());
  }, [dispatch]);

  useEffect(() => {
    if (isInvalidMerchant) {
      navigate('/aboutus');
    }
  }, [isInvalidMerchant, navigate]);

  useEffect(() => {
    if (screenInfo && screenInfo?.screenData) {
      setScreen(screenInfo?.screenData.find((screens: any) => screens?.screenId === 1));
    }
  }, [screenInfo]);

  useEffect(() => {
    if (merchant?.accessToken) {
      localStorage.setItem('token', merchant.accessToken);
    }
  }, [merchant]);

  useEffect(()=>{
    if(!giftToken && storedToken){
      dispatch(setGiftdata({giftCode: storedToken?.giftCode}));
      dispatch(getTokenStatus({ merchantId: merchant?.merchantId, giftCode: storedToken?.giftCode, action: 1}));
    }
  },[storedToken])

  useEffect(() => { 
    if(merchantTypeId === 2){
      
      if(token === undefined){

        if ((giftToken.result?.status === 3 || giftToken.result?.status === 1) && giftToken?.status_code !== -99) {
          setHidePlayButton(false);
          setHideRecordButton(true);
          setShowBothButtons(false);
          setHideRecordButton(false)
        }
        else{
          setShowBothButtons(false);
          setHidePlayButton(false);
          setHideRecordButton(true)
        }
      }
      if(giftToken){
        if ((giftToken.result?.status === 3 || giftToken.result?.status === 1) && giftToken?.status_code === 200) {
          setHidePlayButton(true);
          setHideRecordButton(false);
          setShowBothButtons(false);
        } else if(giftToken.result?.status === 2){
          setHidePlayButton(false);
          setHideRecordButton(true);
          setShowBothButtons(false);
        } 
      if (giftToken?.status_code === -99 && giftToken?.result === null) {
        setShowInvalidMessage(true);
        setShowBothButtons(false);
        setHidePlayButton(true);
        setHideRecordButton(true);
      }
    }
    if(storedToken){
        setShowBothButtons(false);
        setHidePlayButton(true);
        setHideRecordButton(false);
      }
    }

    //merchnat offline
    if(merchantTypeId === 1){
      console.log('merchant: offline');
                      
      if(token === undefined){
        console.log('token: undefined', token);

        if (giftToken.result?.status !== 2 && giftToken?.status_code !== -99) {
          setHidePlayButton(true);
          setHideRecordButton(false);
          setShowBothButtons(false);
          setHideRecordButton(false)
        }
        else{
          console.log('merchant: online');
          setHideRecordButton(true)
          setHidePlayButton(false)
          setShowBothButtons(true);
        }
      }
      if(giftToken){
          console.log('merchant: giftToken');
        
      if((giftToken.result?.status === 0 || giftToken.result?.status === 3) && isTokenPresent){
        console.log('new or draft');
        
        setHidePlayButton(true);
        setHideRecordButton(false);
        setShowBothButtons(false);
      }
      else{
        setHideRecordButton(true);
        setHidePlayButton(true);
        setShowBothButtons(true);
      }
    }
    }
  }, [giftToken, merchant, token]);

  const startRecording = () => {
    if(merchantTypeId == 2){
      navigate(`senderdata`)
    }
    else{
      if (merchant.requestToken) {
        navigate(`giftcode`);
      } else {
        navigate(`senderdata`);
      }
    }
    
  };

  const invalidTokenRedirect = () => {
    if (merchant.siteUrl) {
      window.location.href = merchant.siteUrl;
    } else {
      navigate(`/`);
    }
  };

  if((giftToken?.result?.status == 1 || giftToken?.result?.status == 2) && playMessage?.statusCode === 200 && isTokenPresent){
    // navigate(`/${id}/play/giftmessage`);
    return (
      <PlayMessage></PlayMessage>
    );
  }else{
  return (
    <Container>
      <InnerContainer merchantLogo={merchant?.logo} hrefLink={isRedirect ? `${merchant?.siteUrl}` : '../'} bgColor={merchant?.backgroundColor} header={<div className='mt-[18px]'></div>}>
        <>
          <div>
            <MainTitle align='center' size='3xl'>{screen ? screen.data.title : ''}</MainTitle>
            <div className="mt-6 text-center">
              <Paragraph align='center'>
                <div dangerouslySetInnerHTML={{ __html: screen?.data?.description }} />
              </Paragraph>
            </div>
          </div>
          <div className='flex items-center justify-center'>
          {config?.merchentUrl && screen?.data?.image ? (
            <img id='welcomeImg' className='object-cover w-full h-[250px] rounded-2xl' src={config && screen ? config.merchentUrl + screen.data.image : ''} />
          ) : <div className='object-cover w-full h-[250px] rounded-2xl'></div>}
            </div>
          <div className='w-full'>
            <div className="flex flex-col justify-center gap-4">
              {!hidePlayButton && (
                <Button
                  type='base'
                  btnTextColor={merchant?.buttonTextColor}
                  btnBorderColor={merchant?.buttonBorderColor}
                  btnBgColor={merchant?.buttonBackgroundColor}
                  btnBgColorHover={merchant?.buttonHoverBackgroundColor}
                  btnTextColorHover={merchant?.buttonHoverTextColor}
                  btnBorderColorHover={merchant?.buttonHoverBorderColor}
                  linkUrl='play'
                  // onClick={ ()=>{navigate(`../play/giftmessage`)}}
                  fullwidth={true}
                >
                  {screen ? screen.data.playButton : ''}
                </Button>
              )}
              {showInvalidMessage && (
                <>
                  <Paragraph align='center'>
                    {screen ? screen.data.homeButtonMessage : ''}
                  </Paragraph>
                  <Button
                    type='whatsapp'
                    btnTextColor={merchant?.buttonTextColor}
                    btnBorderColor={merchant?.buttonBorderColor}
                    btnBgColor={merchant?.buttonBackgroundColor}
                    btnBgColorHover={merchant?.buttonHoverBackgroundColor}
                    btnTextColorHover={merchant?.buttonHoverTextColor}
                    btnBorderColorHover={merchant?.buttonHoverBorderColor}
                    onClick={invalidTokenRedirect}
                  >
                    {screen ? screen.data.homeButton : ''}
                  </Button>
                </>
              )}
              {showBothButtons && !showInvalidMessage && (
                <>
                  <Button
                    type='base'
                    btnTextColor={merchant?.buttonTextColor}
                    btnBorderColor={merchant?.buttonBorderColor}
                    btnBgColor={merchant?.buttonBackgroundColor}
                    btnBgColorHover={merchant?.buttonHoverBackgroundColor}
                    btnTextColorHover={merchant?.buttonHoverTextColor}
                    btnBorderColorHover={merchant?.buttonHoverBorderColor}
                    onClick={ ()=>{navigate(`../play/`)}}
                    fullwidth={true}
                  >
                    {screen ? screen.data.playButton : ''}
                  </Button>

                  <Button
                    type='line'
                    btnTextColor={merchant?.buttonBorderColor}
                    btnBorderColor={merchant?.buttonBorderColor}
                    btnBgColor={merchant?.buttonBackgroundColor}
                    btnBgColorHover={merchant?.buttonHoverBackgroundColor}
                    btnTextColorHover={merchant?.buttonHoverTextColor}
                    btnBorderColorHover={merchant?.buttonHoverBorderColor}
                    onClick={startRecording}
                  >
                    {screen ? screen.data.recordButton : ''}
                  </Button>                
                </>
              )}

              {!hideRecordButton && !showBothButtons && !showInvalidMessage && (
                <Button
                type='base'
                btnTextColor={merchant?.buttonTextColor}
                btnBorderColor={merchant?.buttonBorderColor}
                btnBgColor={merchant?.buttonBackgroundColor}
                btnBgColorHover={merchant?.buttonHoverBackgroundColor}
                btnTextColorHover={merchant?.buttonHoverTextColor}
                btnBorderColorHover={merchant?.buttonHoverBorderColor}
                onClick={startRecording}
              >
                {screen ? screen.data.recordButton : ''}
              </Button> 
              )}
            </div>
          </div>
        </>
      </InnerContainer>
    </Container>
  );
}
};

export default Welcome;