import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import { useNavigate, useParams } from "react-router-dom";
import { MainTitle, Container, InnerContainer, Button, InputField } from "app-premitives/index";
import { useDispatch, useSelector } from "react-redux";
import { getOccassion, getScreenInfo, setGiftdata } from "app-pages/merchant/merchant.slice";
import OccassionSlider from "./slider";
import { selectOccasionList, selectGiftData, selectConfigs, selectScreenInfo, selectMerchant } from 'app-root/pages/merchant/merchant.selector'
import backButton from 'app-assets/images/caret-left-solid.svg'
import usePageTimeout from "app-root/core/theme/page-timeout.hook";
const Occasion: React.FC = () => {

  const dispatch = useDispatch();
  const occasionList = useSelector(selectOccasionList);

  console.log(occasionList)

  const giftData = useSelector(selectGiftData);
  const [activeSlide, setActiveSlide] = useState(0);
  const [activeSlide2, setActiveSlide2] = useState(0);
  
  const [occasionId, setOccasionId] = useState("");
  const [occasionDesc, setOccassionDesc] = useState("");
  const [occasionColor, setOccasionColor] = useState("");
  const [occasionImg, setOccasionImg] = useState("");
  const [occasionImageName, setOccasionImageName] = useState("");
  const [occasionBg, setOccasionBg] = useState("");
  const [playBackgroundUrl, setPlayBackgroundUrl] = useState("");
  const [playColor1, setPlayColor1] = useState("");
  const [playColor2, setPlayColor2] = useState("");
  const [playGradiant1, setPlayGradiant1] = useState("");
  const [playGradiant2, setPlayGradiant2] = useState("");
  const [playGradiant3, setPlayGradiant3] = useState("");
  const [playGradiant4, setPlayGradiant4] = useState("");
  const [playBackgroundColor, setPlayBackgroundColor] = useState("");
  const [ribbon, setRibbon] = useState("");
  const [playBackgroundUrl_Footer, setPlayBackgroundUrl_Footer] = useState("");

  const selectConfig = useSelector(selectConfigs);
  const merchant = useSelector(selectMerchant);
  const navigate = useNavigate();
  const [isChecked, setIsChecked] = useState(true);
  const screenInfo = useSelector(selectScreenInfo);
  const [screen, setScreen] = useState(null) as any
  const isRedirect = screenInfo?.screenData?.find((screens:any) => screens.screenId === 1)?.data?.redirectToMerchentSite;

  usePageTimeout();

  useEffect(()=>{
    if(!giftData.senderfname && !giftData.sendermail && !giftData.recieverfname){
      navigate(`/${id}/`)
    }
  
},[])

  useEffect(() => {
    console.log('screen info', screenInfo.screenData)
    if (merchant && screenInfo.screenData === undefined) {
      dispatch(getScreenInfo({merchantId: merchant?.merchantId}))
    }
  }, [merchant]);

  useEffect(()=>{
    if (screenInfo && screenInfo.screenData) {
      setScreen(screenInfo.screenData.find((screens:any) => screens.screenId === 5))
    }
  },[screenInfo && screenInfo?.screenData])
  
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerPadding: '50px',
    centerMode: true,
    arrows: false,

    beforeChange: (current: any, next: any) => {
      setActiveSlide(next);
    },
    afterChange: (current: any) => {
      setActiveSlide2(current);
      
    },
  };

  const { id } = useParams();

  const getActiveOccasionText = () : any => {
    const activeOccasion = occasionList[activeSlide];
    if (activeOccasion) {
      return {
        occasionId: activeOccasion.id,
        occasionName : activeOccasion.name,
        occasionDesc: activeOccasion.message,
        occasionColor: activeOccasion.colorCode,
        occasionImg: activeOccasion.imageUrl,
        occasionImgName: activeOccasion.imageName,
        occasionBg: activeOccasion.backgroundUrl,
        playBackgroundUrl: activeOccasion.playBackgroundUrl,
        playColor1: activeOccasion.playColor1,
        playColor2: activeOccasion.playColor2,
        playGradiant1: activeOccasion.playGradiant1,
        playGradiant2: activeOccasion.playGradiant2,
        playGradiant3: activeOccasion.playGradiant3,
        playGradiant4: activeOccasion.playGradiant4,
        playBackgroundColor: activeOccasion.playBackgroundColor,
        ribbonImageUrl: activeOccasion.ribbonImageUrl,
        playBackgroundUrl_Footer: activeOccasion.playBackgroundUrl_Footer,
      }; 
    }else{
      return "";
    }
  };

  useEffect(() => {
    dispatch(getOccassion());
  }, []);

  const onSubmit = () => {
    const activeOccasion = getActiveOccasionText();
      const selectedOccasionData = occasionList[activeOccasion.occasionName];
      setOccasionId(activeOccasion.occasionId);
      setOccassionDesc(activeOccasion.occasionDesc);
      setOccasionColor(activeOccasion.occasionColor);
      setOccasionImg(activeOccasion.occasionImg);
      setOccasionImageName(activeOccasion.imageName);
      setOccasionBg(activeOccasion.backgroundUrl);
      setPlayBackgroundUrl(activeOccasion.backgroundUrl);
      setPlayColor1(activeOccasion.playColor1);
      setPlayColor2(activeOccasion.playColor2);
      setPlayGradiant1(activeOccasion.playGradiant1);
      setPlayGradiant2(activeOccasion.playGradiant2);
      setPlayGradiant3(activeOccasion.playGradiant3);
      setPlayGradiant4(activeOccasion.playGradiant4);
      setPlayBackgroundColor(activeOccasion.playBackgroundColor);
      setRibbon(activeOccasion.ribbonImageUrl);
      setPlayBackgroundUrl_Footer(activeOccasion.playBackgroundUrl_Footer);
      
      dispatch(setGiftdata({
        activeOccasion: {
          ...activeOccasion,
          voiceRecord: isChecked
        },
      }));

      if(isChecked==true){
        navigate(`/${id}/imageupload`)
      }
      else{
        navigate(`/${id}/message`)
      }

  };

  return (
    <Container>
      <InnerContainer merchantLogo={merchant?.logo} hrefLink={isRedirect ? `../${id}` : '../'} bgColor={merchant?.backgroundColor} header={
        <div className="flex items-center text-[12px] font-medium cursor-pointer" onClick={() => {
          navigate(`/${id}/recieverdata`)
        }}>
          <img src={backButton} className="h-[12px] w-[12px]" />
          Back
        </div>
      }>
        <>
          <div className="w-full">
            {/* <MainTitle size="3xl" align="center">Nice! What do you want to send to <span className="text-[#566CED]">{giftData.recieverfname}?</span></MainTitle> */}
            <MainTitle size="3xl" align="center">{screen ? (
              <>
                {screen.data.title.replace("{0}", giftData.recieverfname).split(giftData.recieverfname).map((part: any, index: any) => (
                  <span key={index}>
                    {index > 0 && (
                      <span style={{ color: `${merchant?.textboxFocusColor ?? '#566CED'}` }}>
                        {giftData.recieverfname}
                      </span>
                    )}
                    {part}
                  </span>
                ))}
              </>
            ) : ("")}</MainTitle>
            </div>

            {/* <div className="flex items-center justify-center w-full gap-5 my-6">          
              <label htmlFor="1" className="text-lg font-semibold text-black">I want to record voice message</label>
              <div className="relative inline-block w-10 mr-2 align-middle transition duration-200 ease-in select-none">
              <input type="checkbox" id="1"
            className="absolute block w-6 h-6 bg-white border-4 rounded-full appearance-none cursor-pointer toggle-checkbox"
            checked={isChecked} onChange={() => setIsChecked(!isChecked)}
          />
                <label htmlFor="1" className="block h-6 overflow-hidden bg-gray-300 rounded-full cursor-pointer toggle-label"></label>
              </div>
          </div> */}

          <div className="w-full mb-8">
            <div className="mb-4">
              <strong>{screen ? screen.data.occationSelect : ''}</strong>
            </div>
            <Slider {...settings}>
              {occasionList.map((occasion: any, index: number) => (
                <OccassionSlider
                  key={index}
                  occasion={occasion}
                />
              ))}
            </Slider>
          </div>

          <div className='w-full'>
            <div className="flex flex-col justify-center">
              <Button type='base' btnTextColor={merchant?.buttonTextColor} btnBgColor={merchant?.buttonBackgroundColor} btnBgColorHover={merchant?.buttonHoverBackgroundColor} btnBorderColor={merchant?.buttonBorderColor} btnTextColorHover={merchant?.buttonHoverTextColor} btnBorderColorHover={merchant?.buttonHoverBorderColor} fullwidth={true} linkUrl={`/${id}/message`} onClick={onSubmit}>{screen ? screen.data.continueButton : ''}</Button>
            </div>
          </div>
        </>
      </InnerContainer>
    </Container>
  );
}

export default Occasion;
