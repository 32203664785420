import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import { useNavigate, useParams } from "react-router-dom";
import { MainTitle, Container, ModelForm, InnerContainer, LargeButton, LinkButton, InputField, Button, Info } from "app-premitives/index";
import { Form } from "antd";
import { useDispatch, useSelector } from "react-redux";
import useMerchantInfo from "app-core/theme/theme.hook";
import { getScreenInfo, setGiftdata, getMerchants } from "app-pages/merchant/merchant.slice";
import backButton from 'app-assets/images/caret-left-solid.svg';
import { selectGiftData, selectScreenInfo, selectMerchant, selectConfigs } from "app-pages/merchant/merchant.selector";
import usePageTimeout from "app-root/core/theme/page-timeout.hook";

const Senderdata: React.FC = () => {
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };

  const { id } = useParams();
  const navigate = useNavigate();

  const [firstName, setFirstName] = useState("");

  const dispatch = useDispatch();
  const [formData, setFormData] = useState({});
  const giftData = useSelector(selectGiftData);
  const screenInfo = useSelector(selectScreenInfo);
  const [screen, setScreen] = useState(null) as any
  const merchant = useSelector(selectMerchant);
  const merchantId = merchant?.merchantId
  const theme = useMerchantInfo({merchantId: id});
  const isRedirect = screenInfo?.screenData?.find((screens:any) => screens.screenId === 1)?.data?.redirectToMerchentSite;
  const merchantTypeId = merchant?.typeId;

  const [model, setModel] = useState({
    senderfname: giftData.senderfname ?? "",
    senderlname: giftData.senderlname ?? "",
    sendermail: giftData.sendermail ?? ""
  });

  usePageTimeout();

  useEffect(()=>{
    if(theme===undefined)
    dispatch(getMerchants({ theme }))
  },[theme])

  useEffect(()=>{
    if(merchantTypeId === 2){
      if(!giftData.giftCode){
        navigate(`/${id}/`)
      }
    }
    
  },[])

  useEffect(() => {
    if (merchant && screenInfo.screenData === undefined) {
      dispatch(getScreenInfo({merchantId: merchant?.merchantId}))
    }
  }, [merchant]);

  useEffect(()=>{
    if (screenInfo && screenInfo.screenData) {
      setScreen(screenInfo.screenData.find((screens:any) => screens.screenId === 3))
    }
  },[screenInfo && screenInfo?.screenData])

  const handleInputChange = (fieldName: string, value: any) => {
    setFormData({
      ...formData,
      [fieldName]: value,
    });
  };

  const onFinish = () => {
    dispatch(setGiftdata({ ...formData }));
    navigate(`/${id}/recieverdata`)
  };

  const [form] = Form.useForm();

  return (
    <Container>
      <InnerContainer bgColor={merchant?.backgroundColor} hrefLink={isRedirect ? `../${id}` : '../'} merchantLogo={merchant?.logo} header={
        <div className="flex items-center text-[12px] font-medium cursor-pointer" onClick={() => {
          if(merchantTypeId === 2){
            dispatch(setGiftdata({ ...formData }));
          }
          navigate(`/${id}/`)

        }}>
          <img src={backButton} className="h-[12px] w-[12px]" />
          Back
        </div>
      }>
        <>
          <div className="w-full">
            <MainTitle size="3xl" align="center">{screen ? screen.data.title : ''}</MainTitle>
            <div className="mt-6">
              <ModelForm form={form} model={model} onFinish={onFinish}>
                <Form.Item
                  label={screen ? screen.data.firstName : ''}
                  name="senderfname"
                  rules={[
                    {
                      required: true,
                      message: "Please input the First Name!",
                    },
                  ]}
                >
                  <InputField
                    borderColor={merchant?.textboxFocusColor}
                    hoverBorderColor={merchant?.textboxHoverColor}
                    bgColor={merchant?.textboxBackgroundColor}
                    hint={screen ? screen.data.firstName : ''}
                    onChange={(e: any) => handleInputChange("senderfname", e.target.value)}
                  />
                </Form.Item>
                <Form.Item
                  label={screen ? screen.data.lastName : ''}
                  name="senderlname"
                  rules={[
                    {
                      required: false,
                      message: "Please input the Last Name!",
                    },
                  ]}
                >
                  <InputField
                    borderColor={merchant?.textboxFocusColor}
                    hoverBorderColor={merchant?.textboxHoverColor}
                    bgColor={merchant?.textboxBackgroundColor}
                    hint={screen ? screen.data.lastName : ''}
                    onChange={(e: any) => handleInputChange("senderlname", e.target.value)}
                  />
                </Form.Item>
                <Form.Item
                  label={screen ? screen.data.mailAddress : ''}
                  name="sendermail"
                  rules={[
                    {
                      type: "email",
                      message: "The input is not a valid E-mail!",
                    },
                    {
                      required: true,
                      message: "Please input the email address!",
                    },
                  ]}
                >
                  <InputField
                    borderColor={merchant?.textboxFocusColor}
                    hoverBorderColor={merchant?.textboxHoverColor}
                    bgColor={merchant?.textboxBackgroundColor}
                    hint={screen ? screen.data.mailAddress : ''}
                    onChange={(e: any) => handleInputChange("sendermail", e.target.value)}
                  />
                </Form.Item>
                
              </ModelForm>
            </div>
          </div>

          <div className='w-full'>
            <div className="flex flex-col justify-center">
              <div className='mt-4'>
                <Button type='base' btnTextColor={merchant?.buttonTextColor} btnBgColor={merchant?.buttonBackgroundColor} btnBgColorHover={merchant?.buttonHoverBackgroundColor} btnBorderColor={merchant?.buttonBorderColor} btnTextColorHover={merchant?.buttonHoverTextColor} btnBorderColorHover={merchant?.buttonHoverBorderColor} fullwidth={true} onClick={() => form.submit()}>{screen ? screen.data.continueButton : ''}</Button>
              </div>
            </div>
          </div>
        </>
      </InnerContainer>
    </Container>
  );
};

export default Senderdata;
