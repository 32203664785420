import React from 'react';
import { Title, Container, ModelForm, Button, InnerContainer } from 'app-premitives/index';
import { Form, Input } from 'antd';

const Giftfor: React.FC = () => {
  return (
    <Container>
      <InnerContainer>
        <>
          <div>
            <Title>Who is the gift for?</Title>
            <div className="mt-8">
              <ModelForm>
                <Form.Item
                  label="Who is this for?"
                  name="thisfor"
                  rules={[
                    {
                      required: true,
                      message: 'Please input who is this for!',
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label="Who is this from?"
                  name="thisfrom"
                  rules={[
                    {
                      required: true,
                      message: 'Please input who is this from!',
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </ModelForm>
            </div>
          </div>
        </>
      </InnerContainer>
    </Container>
  );
};

export default Giftfor;
