import React from "react";
import Header from "app-components/header";

const Faq = () => {
  return (
    <div className="">
      <Header />
      <div>Faq</div>
    </div>
  );
};

export default Faq;
