  const getRecordData = () => {
    let recordData = { }
    if(localStorage.getItem('recordData')){
      
      const recordDataString : any = localStorage.getItem('recordData');
      recordData = JSON.parse(recordDataString)

    }
    return recordData
  }

  const setRecordData = (data: any) => {
    const recordData = getRecordData()
    const newRecordData = {...recordData, ...data}

    localStorage.setItem("recordData", JSON.stringify(newRecordData))
  }

  const getDraftData = () => {
    let draftData = { }
    if(localStorage.getItem('draftMessage')){
      
      const draftDataString : any = localStorage.getItem('draftMessage');
      draftData = JSON.parse(draftDataString)

    }
    return draftData
  }

  const setDraftData = (data: any) => {
    const draftData = getDraftData()
    const newDraftData = {...draftData, ...data}

    localStorage.setItem("draftMessage", JSON.stringify(newDraftData))
  }

  const getOccassionsData = () => {
    let occassionsData = []
    if(localStorage.getItem('Ocassions')){
      const OcassionsString : any = localStorage.getItem('Ocassions');
      occassionsData = JSON.parse(OcassionsString)
    }
    return occassionsData
  }

  const setOccassionsData = (data: any) => {
    const occassionsData = getOccassionsData()
    const newOccassionsData = [...occassionsData, ...data]

    localStorage.setItem("Ocassions", JSON.stringify(newOccassionsData))
  }

  const getMerchantData = () => {
    let merchantData = {}
    if(localStorage.getItem('MerchantInfo')){
      const merchantString : any = localStorage.getItem('MerchantInfo');
      merchantData = JSON.parse(merchantString)
    }
    return merchantData
  }

  const setMerchantData = (data: any) => {
    const merchantData = getMerchantData()
    const newMerchantData = {...merchantData, ...data}

    localStorage.setItem("MerchantInfo", JSON.stringify(newMerchantData))
  }

  const getConfigData = () => {
    let configData = {}
    if(localStorage.getItem('Config')){
      const configString : any = localStorage.getItem('Config');
      configData = JSON.parse(configString)
    }
    return configData
  }

  const setConfigData = (data: any) => {
    const configData = getConfigData()
    const newConfigData = {...configData, ...data}

    localStorage.setItem("Config", JSON.stringify(newConfigData))
  }

  const getAudioData = () => {
    let audioData = {}
    if(localStorage.getItem('recordedAudio')){
      const audioString : any = localStorage.getItem('recordedAudio');
      audioData = JSON.parse(audioString)
    }
    return audioData
  }

  const setAudioData = (data: any) => {
    const audioData = getAudioData()
    const newAudioData = {...audioData, ...data}

    localStorage.setItem("recordedAudio", JSON.stringify(newAudioData))
  }

  const getCreateMessageData = () => {
    let createMessageData = {}
    if(localStorage.getItem('createMessage')){
      const createMessageString : any = localStorage.getItem('createMessage');
      createMessageData = JSON.parse(createMessageString)
    }
    return createMessageData
  }

  const setCreateMessageData = (data: any) => {
    const createMessageData = getCreateMessageData()
    const newCreateMessage = {...createMessageData, ...data}

    localStorage.setItem("createMessage", JSON.stringify(newCreateMessage))
  }

  const getPlayMessageData = () => {
    let playMessageData = {}
    if(localStorage.getItem('message')){
      const createPlayString : any = localStorage.getItem('message');
      playMessageData = JSON.parse(createPlayString)
    }
    return playMessageData
  }
  const setPlayMessageData = (data: any) => {
    const playMessageData = getPlayMessageData()
    const newPlayMessage = {...playMessageData, ...data}

    localStorage.setItem("message", JSON.stringify(newPlayMessage))
  }

  const getDefaultMerchantData = () => {
    let defaultMerchantData = {}
    if(localStorage.getItem('default_merchant')){
      const defaultMerchantString : any = localStorage.getItem('default_merchant');
      defaultMerchantData = JSON.parse(defaultMerchantString)
    }
    return defaultMerchantData
  }

  const setDefaultMerchantData = (data: any) => {
    const defaultMerchantData = getDefaultMerchantData()
    const newDefaultMerchant = {...defaultMerchantData, ...data}

    localStorage.setItem("default_merchant", JSON.stringify(newDefaultMerchant))
  }

  const getScreenInfoData = () => {
    let screenInfoData = {}
    if(localStorage.getItem('screen_info')){
      const screenInfoString : any = localStorage.getItem('screen_info');
      screenInfoData = JSON.parse(screenInfoString)
    }
    return screenInfoData
  }

  const setScreenInfoData = (data: any) => {
    const screenInfoData = getScreenInfoData()
    const newScreenInfoData = {...screenInfoData, ...data}

    localStorage.setItem("screen_info", JSON.stringify(newScreenInfoData))
  }

  export { getRecordData, setRecordData, getOccassionsData, setOccassionsData, getMerchantData, setMerchantData, getConfigData, setConfigData, getAudioData, setAudioData, getCreateMessageData, setCreateMessageData, getPlayMessageData, setPlayMessageData, getDefaultMerchantData, setDefaultMerchantData, getScreenInfoData, setScreenInfoData, getDraftData, setDraftData }
