import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import Header from "app-components/header";
import ShareHeartFelt from "app-pages/home/shareheartfelt";
import HowItWorks from "app-pages/home/howitworks";
import WhyChooseUs from "app-pages/home/whychooseus"
import RecordNow from "app-pages/home/recordnow";
import Free from 'app-pages/home/absolutelyfree'
import Footer from 'app-components/footer'
import voiceyMobile from 'app-assets/images/voicey_mobile.png'
import celebrateIcon from 'app-assets/images/celebrate-icon.png'
import { List, MainTitle, Title } from "app-root/components/premitives";
import { ListItemProps } from "app-root/components/premitives/premitives.type";
import { selectMerchant, selectOccasionList } from "app-pages/merchant/merchant.selector";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getOccassion, getConfig, getDefaultMerchant, getScreenInfo } from "app-root/pages/merchant/merchant.slice";
import OccassionSlider from "./slider";
// import congrats from 'app-assets/images/occassions/Congratulations.png'
import birthday from 'app-assets/images/occassions/Happy Birthday.png'
import fathersday from 'app-assets/images/occassions/Happy Fathers Day.png'
import valentines from 'app-assets/images/occassions/Happy Valentines Day.png'
import justbcoz from 'app-assets/images/occassions/Just Because.png'
import thanks from 'app-assets/images/occassions/Thank you.png'


const workSteps: ListItemProps[] = [
  { id: 1, text: "Choose your theme", isChecked: true },
  { id: 2, text: "Add your Pix", isChecked: true },
  { id: 3, text: "Add your Voicey", isChecked: true },
  { id: 4, text: "Send with love", isChecked: true },
];

const Home = () => {
  const logoUrl = process.env.REACT_APP_LOGO_URL;
  const merchant = useSelector(selectMerchant)
  const siteName = merchant.siteName
  //const occasionList = useSelector(selectOccasionList);
  const occasionList = [birthday, fathersday, valentines, justbcoz, thanks];
  const [ready, setReady] = useState<Boolean>(false);
  const [activeSlide, setActiveSlide] = useState(0);
  const [activeSlide2, setActiveSlide2] = useState(0);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if(!ready){
      localStorage.clear();
      setReady(true);
    }
  }, [ready]);

  useEffect(() => {
    dispatch(getDefaultMerchant());
  }, [ready]);

  useEffect(() => {
    dispatch(getOccassion());
  }, [ready]);

  useEffect(() => {
    dispatch(getConfig());
  }, [ready]);
  
  useEffect(() => {
    if(ready && merchant?.merchantId){
      dispatch(getScreenInfo({ merchantId: merchant?.merchantId }))
    } 
  }, [merchant]);

  var settings = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerPadding: '50px',
    centerMode: true,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
    beforeChange: (current: any, next: any) => {
      setActiveSlide(next);
    },
    afterChange: (current: any) => {
      setActiveSlide2(current);
    },
  };

  const onStartClick = () => {
    if(ready && merchant?.merchantId){
      navigate(`${siteName}/`)
    }
  }

  return (
    <>
      <div className="xl:w-[1280px] w-full py-5">
        {/* header */}
        <div className="flex flex-col items-center justify-between gap-3 md:flex-row md:gap-0">
          <div><img className="w-52" src={logoUrl} alt="Voicey Pix Logo" /></div>
          <div>
            <div className="flex flex-col gap-3">
              <h1 className="text-3xl font-bold text-center text-purple-500 md:text-5xl">Welcome to Voicey Pix</h1>
              <h2 className="text-lg font-bold text-center md:text-xl">personalised e-cards with love for all occasions  </h2>
            </div>
          </div>
          <div className="my-2 md:mt-0">
            {/* <div className="flex gap-5 text-sm font-bold md:flex-col">
              <a>Login / Register</a>
              <a>Pricing / Membership</a>
            </div> */}
          </div>
        </div>

        {/* body */}
        <div className="mt-8 md:flex md:h-[80vh] md:flex-col justify-center">
          <div className="flex flex-col items-center justify-around md:flex-row">
            <div className="flex flex-col gap-5">
              <div>
                <h3 className="my-2 text-xl font-bold text-center text-purple-500 md:my-0 md:text-lg">How it works</h3>
              </div>
              <div>
                <List items={workSteps} />
              </div>
            </div>

            <div className="w-[400px] home-slider">
              {/* <Slider {...settings}>
                {occasionList.map((occasion: any, index: number) => (
                  <OccassionSlider
                    key={index}
                    occasion={occasion}
                  />
                ))}
              </Slider> */}
              <Slider {...settings}>
                {occasionList.map((image, index) => (
                  <img key={index} className="home-slider-img" src={image} alt={`Occasions`} />
                ))}
              </Slider>
            </div>

            <div className="flex flex-col items-center my-6 md:my-0">
              <img src={celebrateIcon} alt="" />
              <h3 className="font-bold text-center">Free<br />until 31 March 2024</h3>
            </div>
          </div>

          <div className="flex items-center justify-center my-5">
            <button className="px-20 py-5 text-5xl font-bold text-white bg-purple-500 rounded-3xl" onClick={onStartClick}>Start</button>
          </div>
        </div>
      </div>
    </>
  );
};

// Custom Arrow Components
const CustomPrevArrow = (props: any) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block",  }}
      onClick={onClick}
    >

    </div>
  );
};

const CustomNextArrow = (props: any) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block" }}
      onClick={onClick}
    >
    
    </div>
  );
};

export default Home;