import React from "react";
import { navigation } from "./utils";
import { useNavigate } from "react-router-dom";
import { LinkButton, Button, Paragraph } from "../premitives";
import logo from 'app-assets/images/Recqordlogo.png'

const Footer = () => {
  const navigate = useNavigate();

  const onMenuClick = (menuItem: any) => {
    navigate(`../${menuItem.url}`);
  };

  const set1 = ["pricing", "aboutus"];
  const set2 = ["faq", "privacy"];

  return (
    <div className="w-full bg-[#F3F6FC] flex items-center justify-center">
    <div className="flex flex-col items-center w-full xl:w-[1280px] justify-between text-lg font-semibold md:flex-row md:items-center px-4 md:px-0 pt-10">
        <div>
          <img className="h-[46px] cursor-pointer" src={logo} onClick={()=>{navigate(`/`)}} alt="Recqord Logo" />
          <div className="flex justify-center mt-4 md:justify-end">
          <Paragraph>2024 RECQORD Inc.</Paragraph>
          </div>
          
        </div>
        <div className="flex flex-col items-center justify-center">
        {set1.map((key: string) => (
            <div className="cursor-pointer" key={key} onClick={() => onMenuClick(navigation[key])}>
              {navigation[key].text}
            </div>
          ))}
          </div>
          <div className="flex flex-col items-center justify-center">
        {set2.map((key: string) => (
            <div className="cursor-pointer" key={key} onClick={() => onMenuClick(navigation[key])}>
              {navigation[key].text}
            </div>
          ))}
          </div>
      <div className="my-5">
        <div className="flex justify-center w-full gap-2">
          <Paragraph>Find</Paragraph>
          <img className="h-5" src={logo} />
          <Paragraph>On</Paragraph>
        </div>
        <div className="mt-3 social-icons">
  <div className="flex gap-3">
    <a href="#" className="icon-container"><i className="fa fa-facebook" aria-hidden="true"></i></a>
    <a href="#" className="icon-container"><i className="fa fa-twitter" aria-hidden="true"></i></a>
    <a href="#" className="icon-container"><i className="fa fa-youtube" aria-hidden="true"></i></a>
    <a href="#" className="icon-container"><i className="fa fa-dribbble" aria-hidden="true"></i></a>
  </div>
</div>

        </div>
      </div>
      
    </div>
  );
};

export default Footer;
