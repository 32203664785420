import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';

import { switchMap, tap } from 'rxjs/operators';

const httpPost = ({ call, data }:any) => {
  return httpCall('POST', call, data);
};

const httpGet = ({ call }:any) => {
  return httpCall('GET', call);
};

const httpDelete = ({ call }:any) => {
  return httpCall('DELETE', call);
};

const httpPut = ({ call, data }:any) => {
  return httpCall('PUT', call, data);
};

const httpCall = (type:string, call:string, data:any = null) => {

  let request_url = `${process.env.REACT_APP_API_URL}/api`;
  let headers:any = {
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept',
    'Content-type': 'application/json',
    'Access-Control-Max-Age': 7200,
  };

  let jsonData = null;
  if (data) {
    jsonData = JSON.stringify(data);
  }

  
  const session = localStorage.getItem('token');
     request_url = `${request_url}${call}`;
  if (session !== null || session !== 'undefined') {    
    console.log('session: ', session)
    headers = {
      ...headers,
      Authorization: `Bearer ${session}`
    };
  }

  return ajax({
    url: request_url,
    crossDomain: true,
    method: type,
    responseType: 'json',
    timeout: 0,
    headers,
    body: data ? jsonData : null,
    createXHR: function () {
      return new XMLHttpRequest();
    },
  }).pipe(
    switchMap(data => of(data.response)),
  );
};

const apiStatus = {
  success: 200,
};

export { httpPost, httpGet, httpDelete, httpPut, apiStatus};