import React from "react";
import { useNavigate } from "react-router-dom";

export const LinkButton = ({ link, children, classes }: any) => {
  const navigate = useNavigate();
  const onClick = () => {
    navigate(`/${link}`);
  };
  return (
    <div
      className={`${classes} w-full text-white text-lg font-semibold bg-[#566CED] py-4 px-4 rounded-2xl text-center`}
      onClick={onClick}
    >
      {children}
    </div>
  );
};
