import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Paragraph, Container, InnerContainer, Button, MainTitle, Info, InputField } from 'app-premitives/index';
import { getTokens } from "app-pages/merchant/merchant.slice";
import useMerchantInfo from 'app-core/theme/theme.hook';
import { setGiftdata } from '../merchant.slice';

const selectMerchant = (state: any) => state.merchantSlice.merchant;
const selectGiftData = (state: any) => state.merchantSlice.giftdata;

const Giftcode: React.FC = () => {
  const { id } = useParams();
  const merchantList = useSelector(selectMerchant);

  return (
    <Container>
      <InnerContainer>
        <>
          <div>
            <MainTitle align='center' size='3xl'>Embed Stripe cart in this page</MainTitle>
          </div>

          <div className='w-full'>
            <div className="flex flex-col justify-center">
              <Button type='base' fullwidth={true} linkUrl={`/${id}/payment`}>Pay now</Button>
            </div>
          </div>
        </>
      </InnerContainer>
    </Container>
  );
};

export default Giftcode;
