import React from 'react';
import { Title, Paragraph, Container, LinkButton, InnerContainer } from 'app-premitives/index';

const AudioVideo: React.FC = () => {
  return (
    <Container>
      <InnerContainer>
        <>
          <div>
            <Title>Record your message</Title>
            <div className="mt-4 text-center">
              <Paragraph>
                You have a maximum of 60 seconds to record your audio message.
              </Paragraph>
            </div>
            <div className="flex flex-col justify-center mt-10 gap-4">
              <LinkButton link="#">Start audio recording</LinkButton>
              <div className="text-center">
                <Paragraph>Or</Paragraph>
              </div>
              <LinkButton link="#">Start video recording</LinkButton>
            </div>
          </div>
        </>
      </InnerContainer>
    </Container>
  );
};

export default AudioVideo;