import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Paragraph, Container, InnerContainer, Button, MainTitle, Info, InputField, ModelForm } from "app-premitives/index";
import { setGiftdata, getTokens, getPlayMessage, getTokenStatus, getScreenInfo } from "../merchant.slice";
import { selectToken, invalidToken, selectMerchant, selectScreenInfo } from "app-pages/merchant/merchant.selector";
import { Form } from "antd";
import backButton from 'app-assets/images/caret-left-solid.svg'
import usePageTimeout from "app-root/core/theme/page-timeout.hook";

const PlayGiftcode: React.FC = () => {
  const { id } = useParams();

  const dispatch = useDispatch();
  const token = useSelector(selectToken);
  const merchant = useSelector(selectMerchant);
  const isInvalidToken = useSelector(invalidToken);
  const timer: any = useRef(null);
  const [isVisible, setIsVisible] = useState("invisible");
  const [error, setError] = useState("");
  const [form] = Form.useForm();
  const giftCode = Form.useWatch("giftCode", form);
  const [model, setModel] = useState({});
  const navigate = useNavigate();
  const merchantId = merchant.merchantId
  const action = 2
  const screenInfo = useSelector(selectScreenInfo);
  const [screen, setScreen] = useState(null) as any
  const isRedirect = screenInfo?.screenData?.find((screens:any) => screens.screenId === 1)?.data?.redirectToMerchentSite;

  usePageTimeout();

  useEffect(() => {
    if (giftCode) onChange(giftCode);

    console.log("giftCode ", giftCode);
  }, [giftCode]);

  useEffect(() => {
    if (merchant && screenInfo.screenData === undefined) {
      dispatch(getScreenInfo({merchantId: merchant?.merchantId}))
    }
  }, [merchant]);

  useEffect(()=>{
    if (screenInfo && screenInfo.screenData) {
      setScreen(screenInfo.screenData.find((screens:any) => screens.screenId === 101))
    }
  },[screenInfo && screenInfo?.screenData])

  useEffect(() => {

    if (giftCode === undefined || giftCode === "") {
      // console.log("giftCode", giftCode)
      setIsVisible("invisible");
    } else {
      if (token?.status_code == 200 || token?.statusCode == 200) {
        setIsVisible("visible");
        setError(
          `${token.message}`
        );
        dispatch(getPlayMessage({ merchantId, giftCode }));
      } else {
        setIsVisible("visible");
          setError( 
            `${token.message}`
          );
      }
    }
  }, [token]);

  const onSubmit = () => {
    if (token?.result?.status == 1 || token?.result?.status == 2 && token?.status_code == 200) {
      navigate(`/${id}/play/giftmessage`);
    }
  };
  

  const onChange = (inputGiftCode: any) => {
    if (timer) {
      clearTimeout(timer.current);
    }

    if (inputGiftCode.trim() === "") {
      setIsVisible("invisible");
      return;
    }

    timer.current = setTimeout(() => {
      dispatch(getTokenStatus({ merchantId, giftCode, action}));
      // dispatch(getTokens({ giftCode: inputGiftCode }));
    }, 1000);
  };

  return (
    <Container>
      <InnerContainer merchantLogo={merchant?.logo} hrefLink={isRedirect ? `../${id}` : '../'} bgColor={merchant?.backgroundColor} header={
        <div className="flex items-center text-[12px] font-medium cursor-pointer" onClick={() => {
          navigate("../");
          window.location.reload();
        }}>
          <img src={backButton} className="h-[12px] w-[12px]" />
          Back
        </div>
      }>
        <>
          <div>
            <MainTitle align="center" size="3xl">
            {screen ? screen.data.title : ''}
            </MainTitle>
            <div className="mt-4 text-center">
              <Paragraph align="center">
                <div dangerouslySetInnerHTML={{__html: screen?.data?.description}} />
              </Paragraph>
            </div>
            <div className="flex flex-col justify-center mt-4">
              <ModelForm model={model} form={form}>
                <Form.Item
                  label={screen ? screen.data.giftCode : ''}
                  name="giftCode"
                  rules={[
                    {
                      required: false,
                    },
                  ]}
                >
                  <InputField hint="Enter gift code" borderColor={merchant?.textboxFocusColor} hoverBorderColor={merchant?.textboxHoverColor} bgColor={merchant?.textboxBackgroundColor} />
                </Form.Item>
                <div className={`relative top-[-10px] text-blue-500 mx-4 text-[16px] ${isVisible} text-base`} >
                  {error}
                </div>
              </ModelForm>
            </div>
          </div>

          <div className="w-full">
            <div className="flex flex-col justify-center">
              <Button
                type="base"
                btnTextColor={merchant?.buttonTextColor} btnBgColor={merchant?.buttonBackgroundColor} btnBgColorHover={merchant?.buttonHoverBackgroundColor} btnBorderColor={merchant?.buttonBorderColor} btnTextColorHover={merchant?.buttonHoverTextColor} btnBorderColorHover={merchant?.buttonHoverBorderColor}
                fullwidth={true}
                onClick={onSubmit}
              >
                {screen ? screen.data.continueButton : ''}
              </Button>
              <div className="mt-4">
                <Info>
                  We will charge you €0,99 for sending it, If you dont have a
                  gift code
                </Info>
              </div>
            </div>
          </div>
        </>
      </InnerContainer>
    </Container>
  );
};

export default PlayGiftcode;