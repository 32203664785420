import React from "react";
import { useNavigate } from "react-router-dom";
import { ButtonProp } from "app-premitives/premitives.type";

export const Button = ({ children, type, linkUrl, fullwidth, btnBgColor, btnTextColor, btnBorderColor, btnBgColorHover, btnBorderColorHover, btnTextColorHover, onClick, disabled, ...rest }: ButtonProp) => {

  let buttonStyle = "";

  if (type === "base") {
    buttonStyle = `text-${btnTextColor == "" ? 'white' : `[${btnTextColor}]`} bg-[${!btnBgColor || btnBgColor == "" ? '#566CED' : btnBgColor}] hover:bg-[${btnBgColorHover == "" ? '#566CED' : btnBgColorHover}]  hover:text-[${btnTextColorHover == "" ? '#000' : `${btnTextColorHover}`}] sm:py-4 sm:px-8 py-3 px-4 ${fullwidth ? "w-full" : ""}`;
  } else if (type === "outline") {
    buttonStyle = `text-[#566CED] bg-opacity-10 hover:bg-[#566CED] hover:text-white sm:py-4 sm:px-8 py-3 px-4 ${fullwidth ? "w-full" : ""}`;
  } else if (type === "line") {
    buttonStyle = `text-[${!btnTextColor || btnTextColor == "" ? '#566CED' : btnTextColor}] bg-transparent rounded-none border-none hover:underline hover:underline-offset-8 py-0`;
  }
  if (type === "normal") {
    buttonStyle = `text-${btnTextColor ?? 'black'} bg-[${btnBgColor ?? '#F2F3F4'}]  border-2 hover:text-[${btnTextColorHover ?? '#000'}] hover:bg-[${btnBgColorHover == "" ? '#566CED' : btnBgColorHover}]  sm:py-2 hover:bg-[${btnBgColor}] sm:px-8 py-3 px-4 ${fullwidth ? "w-full" : ""}`;
  }
  if (type === "stop") {
    buttonStyle = `text-white bg-[#FF8579] border-2 border-[#FF8579] hover:border-[#FF8579] hover:bg-opacity-50 sm:py-4 sm:px-8 py-3 px-4 ${fullwidth ? "w-full" : ""}`;
  }
  if (type === "start") {
    buttonStyle = `text-white bg-[#5ABCB1] border-2 border-[#5ABCB1] hover:border-[#5ABCB1] hover:bg-opacity-50 sm:py-4 sm:px-8 py-3 px-4 ${fullwidth ? "w-full" : ""}`;
  }
  if (type === "whatsapp") {
    buttonStyle = `text-white bg-[#5CC642] border-2 border-[#5ABCB1] hover:border-[#5CC642] hover:text-[#5CC642] hover:bg-opacity-10 sm:py-4 sm:px-8 py-3 px-4 ${fullwidth ? "w-full" : ""}`;
  }
  if (type === "playagain") {
    buttonStyle = `text-white bg-[#5ABCB1] border-2 border-[#5ABCB1] hover:border-[#5CC642] hover:text-[#5CC642] hover:bg-opacity-10 sm:py-4 sm:px-8 py-3 px-4 ${fullwidth ? "w-full" : ""}`;
  }
  if (type === "pink") {
    buttonStyle = `text-white bg-[#FF3887] border-2 border-[#FF3887] hover:border-[#FF3887] hover:text-[#FF3887] hover:bg-opacity-10 sm:py-4 sm:px-8 py-3 px-4 ${fullwidth ? "w-full" : ""}`;
  }


  const navigate = useNavigate();
  const onClicks = () => {
    if (linkUrl) {
      navigate(linkUrl);
    }
    if(onClick){
      onClick()
    }
  };

    return (
        <button className={`whitespace-nowrap sm:text-lg text-base font-semibold rounded-2xl border-2 border-[${btnBorderColor}] hover:border-[${btnBorderColorHover}] ${buttonStyle}`}
        onClick={onClicks} {...rest} disabled={disabled}  >
          {children}
        </button>
    );
};