import { MainTitle, Paragraph, Button } from "app-root/components/premitives";
import React from "react";
import pandaImage from "app-assets/images/winking-panda.png"

const Welcome = () => {
  const logoUrl = process.env.REACT_APP_LOGO_URL;
  return (
    <div className="md:flex md:py-[64px] py-[60px] md:px-0 px-4 justify-between">
      <div className="">
        <img src={pandaImage} className="w-full"/>
      </div>
      <div className="flex flex-col gap-8 xl:w-[720px] justify-center text-[#566CED]">
        <div className="flex items-center gap-4">
          <MainTitle size="3xl" align="left" weight="font-bold" className="uppercase">Welcome To</MainTitle>
          <img className="h-8 md:h-10" src={logoUrl} alt="VoiceyPix logo" />
        </div>

        <Paragraph size="xl" weight="font-semibold" color="#566CED" >... where we believe in the power of personal connections and meaningful communication.</Paragraph>
        <Paragraph size="xl" weight="font-semibold" >Our journey began with a simple idea - to create an app that goes beyond ordinary messaging, allowing you to express your love and emotions in a truly unique way.</Paragraph>
        {/* <div className="flex gap-6">
        <Button type="base">Play message</Button>
        <Button type="outline">Start recording</Button>
        </div> */}
      </div>
    </div>
  );
};

export default Welcome;
