import React from "react";
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import Home from "./pages/home";
import AboutUs from "./pages/aboutus";
import Merchant from "./pages/merchant";
import Pricing from "./pages/pricing";
import Faq from "./pages/faq";
import Design from "./pages/design/design";
import Privacy from "./pages/privacy";

const helmetContext = {};

function App() {
  return (
    
    <div className="flex flex-col items-center">
      
      <HelmetProvider context={helmetContext}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/aboutus" element={<AboutUs />} />
          <Route path="/pricing" element={<Pricing />} />
          <Route path="/faq" element={<Faq />} />
          <Route path="/privacy-policy" element={<Privacy />} />
          <Route path="/design" element={<Design />} />
          <Route path="/:id" element={<Merchant />} />
          <Route path="/:id/*" element={<Merchant />} />
          {/* <Route path="/:id/" element={<Merchant />} /> */}
        </Routes>
      </BrowserRouter>
      </HelmetProvider>
    </div>
  );
}

export default App;
