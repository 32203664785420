import React from "react";
import { ListItemProps } from "app-premitives/premitives.type";

interface ListProps {
    items: ListItemProps[];
  }

  export const List: React.FC<ListProps> = ({ items }) => {
    return (
      <ul className="text-[18px]">
        {items.map((item) => (
          <li key={item.id} className={item.isChecked ? "checked flex font-bold mb-4 items-center" : ""}>{item.text}</li>
        ))}
      </ul>
    );
  };

  export const PinkList: React.FC<ListProps> = ({ items }) => {
    return (
      <ul className="text-[18px]">
        {items.map((item) => (
          <li key={item.id} className={item.isChecked ? "checkedPink text-[#2253B7] font-semibold flex mb-4 items-center" : ""}>{item.text}</li>
        ))}
      </ul>
    );
  };
