import React from "react";
import Header from "app-components/header";
import Footer from "app-components/footer"
const Privacy = () => {
  return (
    <div className="">
      <Header />
      <div>Privacy Policy</div>
    </div>
  );
};

export default Privacy;
