import { configureStore} from '@reduxjs/toolkit';
import coreSlice from '../core/core.slice';
import merchantSlice from 'app-pages/merchant/merchant.slice';
import { createEpicMiddleware } from 'redux-observable'
import rootEpic from './root.epic';


const epicMiddleware = createEpicMiddleware();

const store = configureStore({
    reducer: {
        coreSlice,
        merchantSlice
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(epicMiddleware),
})

epicMiddleware.run(rootEpic);
export default store