import React from "react";
import { Input } from 'antd';
import TextArea from "antd/es/input/TextArea";

export const InputField = ({ hint, name, borderColor, hoverBorderColor, bgColor, ...rest }: any) => {
  return(
    <Input name={name}
      className={`w-full rounded-[16px] border-2 border-[#D9D9D9] p-4 text-4 bg-[${!bgColor || bgColor == "" ? '#fff' : bgColor}] focus:border-[${ !borderColor || borderColor == "" ? '#566CED' : borderColor}] hover:border-[${!hoverBorderColor || hoverBorderColor == "" ? '#4CD964' : hoverBorderColor}]`}
      placeholder={hint} { ...rest}
    />
  );
};

export const TextBox = ({ hint, name, rows, value, onChange, ...rest }: any) => {
  const handleChange = (e:any) => {
    const newValue = e.target.value;

    // Notify parent component of the change
    onChange && onChange(newValue);
  };
  return(
    <TextArea
      name={name}
      style={{ height: '100%' }}
      className="w-full h-full rounded-[16px] border-2 border-[#D9D9D9] p-4 text-4 focus:border-[#566CED] hover:border-[#4CD964]"
      placeholder={hint}
      value={value}
      onChange={handleChange}
      {...rest}
    />
  );
};


